export const REQUEST_INIT_STEAM_GAMES = 'REQUEST_INIT_STEAM_GAMES';
export const SUCCEED_INIT_STEAM_GAMES = 'SUCCEED_INIT_STEAM_GAMES';
export const REQUEST_SEARCH_STEAM_GAMES = 'REQUEST_SEARCH_STEAM_GAMES';
export const SUCCEED_SEARCH_STEAM_GAMES = 'SUCCEED_SEARCH_STEAM_GAMES';
export const REQUEST_LAST_UPDATE_STEAM = 'REQUEST_LAST_UPDATE_STEAM';
export const SUCCEED_LAST_UPDATE_STEAM = 'SUCCEED_LAST_UPDATE_STEAM';
export const REQUEST_GET_STEAM_GAMES_COUNT = 'REQUEST_GET_STEAM_GAMES_COUNT';
export const SUCCEED_GET_STEAM_GAMES_COUNT = 'SUCCEED_GET_STEAM_GAMES_COUNT';
export const SET_SELECTED_GENRES_STEAM_GAMES = 'SET_SELECTED_GENRES_STEAM_GAMES';
export const SET_SELECTED_LANGUAGES_STEAM_GAMES = 'SET_SELECTED_LANGUAGES_STEAM_GAMES';
export const SET_SEARCH_LANGUAGES_STEAM_GAMES = 'SET_SEARCH_LANGUAGES_STEAM_GAMES';
export const SET_STEAM_GAMES_RELEASED = 'SET_STEAM_GAMES_RELEASED';
export const SET_STEAM_GAMES_FILTER_DATA = 'SET_STEAM_GAMES_FILTER_DATA';
export const SET_LANGUAGES_COUNT_STEAM_GAMES = 'SET_LANGUAGES_COUNT_STEAM_GAMES';
export const SET_IS_ONLY_CHOSEN_LANGUAGES_STEAM_GAMES = 'SET_IS_ONLY_CHOSEN_LANGUAGES_STEAM_GAMES';
export const SET_PRICE_PLAY = 'SET_PRICE_PLAY';
export const SET_EARLY_ACCESS_STEAM_GAMES = 'SET_EARLY_ACCESS_STEAM_GAMES';
export const SET_REVIEWS_STEAM_GAMES = 'SET_REVIEWS_STEAM_GAMES';
export const SET_IS_FILTER_SELECTED_STEAM_GAMES = 'SET_IS_FILTER_SELECTED_STEAM_GAMES';
export const SET_TYPES_STEAM_GAMES = 'SET_TYPES_STEAM_GAMES';
export const CLEAR_FILTERS_STEAM_GAMES = 'CLEAR_FILTERS_STEAM_GAMES';