import isEqual from "lodash/isEqual";
import {getSteamRecordsRequest, getLastUpdateSteamRequest, getSteamFilterData, exportSteamGamesRecordsRequest, getSteamCountRequest} from "../../services/grpcBufServices";
import {
    requestInitSteamGames,
    requestSearchSteamGames,
    setSearch,
    setSteamGamesSelectedGenres,
    setSteamSelectedLanguage,
    succeedInitSteamGames,
    succeedSearchSteamGames,
    requestLastUpdate,
    succeedLastUpdate,
    setReleased,
    setSteamGamesFilterData,
    setLanguagesCount,
    setPricePlay,
    setEarlyAccess,
    setReviews,
    clearFiltersSteamGames,
    requestGetSteamCount,
    succeedGetSteamCount,
    setTypes
} from "./actions";
import {getIdsList, getRequestOptions, getExportFieldValues} from "../../helpers/utils";
import {requestExport, succeedExport} from "../App/actions";
import {getReleasedValue} from "../../helpers/dateUtils";

export const initSteamGames = () =>
    async (dispatch, getState) => {
        const {app: {token}, steamGames: {limit, offset, languages: languagesOptions, isOnlyChosenLanguages, selectedLanguage, selectedGenres, genres: genresOptions, search, languagesCount}} = getState();
        dispatch(requestInitSteamGames());
        const languageOptions = getRequestOptions(selectedLanguage, languagesOptions);
        const genreOptions = getRequestOptions(selectedGenres, genresOptions);
        const languagesIds = getIdsList(languageOptions);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;

        const response = await getSteamRecordsRequest(token, limit, offset, languagesIds, genreOptions, search, languagesCountValue, false);
        dispatch(succeedInitSteamGames(response));
    };

export const getLastUpdate = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        dispatch(requestLastUpdate());
        const lastUpdate = await getLastUpdateSteamRequest(token);
        dispatch(succeedLastUpdate(lastUpdate));
    };

export const getFilterData = () =>
    async (dispatch, getState) => {
        const {app: {token}} = getState();
        const response = await getSteamFilterData(token);
        dispatch(setSteamGamesFilterData(response))
    };

export const refreshSteamGames = () =>
    async (dispatch, getState) => {
        const {app: {token}, steamGames: {limit, offset, selectedLanguage, defaultDateRange, selectedEarlyAccess, reviews, languages, isOnlyChosenLanguages, selectedPrice, selectedGenres, genres, search, languagesCount, releasedDateRange, types, selectedTypes}} = getState();
        dispatch(requestInitSteamGames());
        const languageOptions = getRequestOptions(selectedLanguage, languages);
        const genresOptions = getRequestOptions(selectedGenres, genres);
        const typesOptions = getRequestOptions(selectedTypes, types);
        const languagesIds = getIdsList(languageOptions);
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;
        const earlyAccessOption = selectedEarlyAccess.length > 1 ? '' : selectedEarlyAccess[0].id;

        const response = await getSteamRecordsRequest(token, limit, offset, languagesIds, genresOptions, search, languagesCountValue, false, releasedDate, priceOption, earlyAccessOption, reviews, typesOptions);

        dispatch(succeedInitSteamGames(response));
    };

export const searchSteamGames = (count) =>
    async (dispatch, getState) => {
        const {app: {token}, steamGames: {limit, reviews, offset, defaultDateRange, selectedLanguage, selectedEarlyAccess, languages, isOnlyChosenLanguages, selectedPrice, selectedGenres, genres, search, languagesCount, releasedDateRange, types, selectedTypes}} = getState();
        const updateLimit = count;
        const updateOffset = offset + limit;
        dispatch(requestSearchSteamGames(updateLimit, updateOffset));
        const languageOptions = getRequestOptions(selectedLanguage, languages);
        const genresOptions = getRequestOptions(selectedGenres, genres);
        const typesOptions = getRequestOptions(selectedTypes, types);
        const languagesIds = getIdsList(languageOptions);
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;
        const earlyAccessOption = selectedEarlyAccess.length > 1 ? '' : selectedEarlyAccess[0].id;

        const response = await getSteamRecordsRequest(token, updateLimit, updateOffset, languagesIds, genresOptions, search, languagesCountValue, false, releasedDate, priceOption, earlyAccessOption, reviews, typesOptions);

        dispatch(succeedSearchSteamGames(response));
    };

export const exportSteamGames = (fields) =>
    async (dispatch, getState) => {
        const {app: {token}, steamGames: {limit, offset, reviews, defaultDateRange, selectedLanguage, languages, selectedGenres, selectedPrice, selectedEarlyAccess, isOnlyChosenLanguages, genres, search, languagesCount, releasedDateRange, types, selectedTypes}} = getState();
        dispatch(requestExport());
        const languageOptions = getRequestOptions(selectedLanguage, languages);
        const genresOptions = getRequestOptions(selectedGenres, genres);
        const typesOptions = getRequestOptions(selectedTypes, types);
        const languagesIds = getIdsList(languageOptions);
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;
        const earlyAccessOption = selectedEarlyAccess.length > 1 ? '' : selectedEarlyAccess[0].id;
        const fieldValues = getExportFieldValues(fields);

        const exportId = await exportSteamGamesRecordsRequest(token, limit, offset, languagesIds, genresOptions, search, languagesCountValue, false, releasedDate, priceOption, earlyAccessOption, reviews, fieldValues, typesOptions);
        dispatch(succeedExport(exportId.task.id));
    };

export const setGenresAsync = genres =>
    async (dispatch) => {
        dispatch(setSteamGamesSelectedGenres(genres));
    };

export const setLanguageAsync = languages =>
    async (dispatch) => {
        dispatch(setSteamSelectedLanguage(languages));
    };

export const setSearchAsync = search =>
    async (dispatch) => {
        dispatch(setSearch(search));
    };

export const setReleasedAsync = (released) =>
    async (dispatch) => {
        dispatch(setReleased(released));
    };

export const setOptionsCountAsync = languagesCount =>
    async (dispatch, getState) => {
        dispatch(setLanguagesCount(languagesCount));
    };

export const setPriceAsync = selectedPrice =>
    async (dispatch, getState) => {
        dispatch(setPricePlay(selectedPrice));
    };

export const setEarlyAccessAsync = earlyAccess =>
    async (dispatch, getState) => {
        dispatch(setEarlyAccess(earlyAccess));
    };

export const setReviewsAsync = earlyAccess =>
    async (dispatch, getState) => {
        dispatch(setReviews(earlyAccess));
    };

export const clearFilters = () =>
    async (dispatch, getState) => {
        dispatch(clearFiltersSteamGames());
    };

export const getSteamCount = (fields) =>
    async (dispatch, getState) => {
        const {app: {token}, steamGames: {limit, offset, selectedLanguage, defaultDateRange, selectedEarlyAccess, reviews, languages, isOnlyChosenLanguages, selectedPrice, selectedGenres, genres, search, languagesCount, releasedDateRange, types, selectedTypes}} = getState();
        !fields && dispatch(requestGetSteamCount());
        const languageOptions = getRequestOptions(selectedLanguage, languages);
        const genresOptions = getRequestOptions(selectedGenres, genres);
        const typesOptions = getRequestOptions(selectedTypes, types);
        const languagesIds = getIdsList(languageOptions);
        const released = getReleasedValue(releasedDateRange);
        const defaultDate = getReleasedValue(defaultDateRange);
        const releasedDate = isEqual(released, defaultDate) ? null : getReleasedValue(releasedDateRange);
        const languagesCountValue = isOnlyChosenLanguages ? {from: selectedLanguage.length, to: selectedLanguage.length} : languagesCount;
        const priceOption = selectedPrice.length > 1 ? '' : selectedPrice[0].id;
        const earlyAccessOption = selectedEarlyAccess.length > 1 ? '' : selectedEarlyAccess[0].id;
        const fieldValues = getExportFieldValues(fields);

        const response = await getSteamCountRequest(token, limit, offset, languagesIds, genresOptions, search, languagesCountValue, false, releasedDate, priceOption, earlyAccessOption, reviews, fieldValues, typesOptions);
        !fields && dispatch(succeedGetSteamCount(response));
        return response.records;
    };

export const setTypesAsync = types =>
    async (dispatch, getState) => {
        dispatch(setTypes(types));
    };