import Moment from "moment";
import "moment-duration-format";
import { extendMoment } from 'moment-range';
import CONST from "../constants/Constants";
import ceil from 'lodash/ceil';
import map from 'lodash/map';

const moment = extendMoment(Moment);

export const convertDateFormat = (date, targetFormat) => {
    return moment(date).format(targetFormat);
}

export const addMonthToDate = countMonth => {
    const today = moment();
    const nextDate = moment(today).add(countMonth, 'M');

    return nextDate.unix();
}

export const convertTimestampFormat = (timestamp, targetFormat) => {
    if (timestamp && timestamp.seconds) {
        return moment.utc(Number(timestamp.seconds) * 1000).format(targetFormat);
    }
}

export const diffBetweenTodayAndDate = (timestamp) => {
    if (timestamp && timestamp.seconds) {
        const today = moment();
        const date = moment(Number(timestamp.seconds) * 1000);
        const diff = date.diff(today, 'days', true);
        return ceil(diff);
    }
}

export const getDateTimeAgo = (timestamp) => {
    if (timestamp && timestamp.seconds) {
        const date = moment(Number(timestamp.seconds) * 1000);
        const agoDate = date.fromNow();
        return agoDate;
    }
}

export const getReleasedValue = released => {
    const defaultReleased = getDefaultReleased();
    const isDefaultReleased = defaultReleased.startDateInput === released.startDateInput && defaultReleased.endDateInput === released.endDateInput;
    if (isDefaultReleased) {
        return null;
    } else if (released) {
        const startDateMoment = moment(released.startDate).set({hours: '00', minutes: '00'});
        const startDateMomentUTC = startDateMoment.utcOffset("+00:00", true);

        const endDateMoment = moment(released.endDate).set({hours: '00', minutes: '00'});
        const endDateMomentUTC = endDateMoment.utcOffset("+00:00", true);

        const from = startDateMomentUTC.unix();
        const to = endDateMomentUTC.unix();

        return {from, to};
    }
}

export const getReleasedISOValue = released => {
    const defaultReleased = getDefaultReleased();
    const isDefaultReleased = defaultReleased.startDateInput === released.startDateInput && defaultReleased.endDateInput === released.endDateInput;
    if (isDefaultReleased) {
        return {};
    } else if (released) {
        const startDateMoment = moment(released.startDate).set({hours: '00', minutes: '00'});
        const startDateMomentUTC = startDateMoment.utcOffset("+00:00", true);

        const endDateMoment = moment(released.endDate).set({hours: '00', minutes: '00'});
        const endDateMomentUTC = endDateMoment.utcOffset("+00:00", true);

        const from = startDateMomentUTC.toISOString();
        const to = endDateMomentUTC.toISOString();

        return {from, to};
    }
};

export const getDefaultReleased = (startDate, endDate) => {
    const FIRST_DATE = startDate ? convertTimestampFormat(startDate, CONST.DATE_STANDARD_FORMAT) : '01/01/2009';
    const firstDateMoment = moment(FIRST_DATE, CONST.DATE_STANDARD_FORMAT);
    const endDateInput = endDate ? convertTimestampFormat(endDate, CONST.DATE_STANDARD_FORMAT) : moment().format(CONST.DATE_STANDARD_FORMAT);
    const endDateValue = moment(endDateInput, CONST.DATE_STANDARD_FORMAT);

    return {startDate: firstDateMoment,
            startDateInput: FIRST_DATE,
            endDate: endDateValue,
            endDateInput: endDateInput,
    };
};

export const getLocalizedTime = (minutes) => {
    let hours = Math.floor(Number(minutes) / 60);
    const HOURS_MINUTE_FORMAT = "h[h] m[m]";
    const MINUTE_SECONDS_FORMAT = "m[m] s[s]";
    const DAYS_HOURS_FORMAT = "d[d] h[h]";

    let format = HOURS_MINUTE_FORMAT;
    if (hours > 24) {
        format = DAYS_HOURS_FORMAT;
    } else if (hours === 0) {
        format = MINUTE_SECONDS_FORMAT;
    }
    const timeFormat = moment.duration(Number(minutes), "minutes").format(format, {
        //trim: false //если какое-то значение равно 0, он не убирает
    });
    return timeFormat;
}

export const isDateInRange = (date, range) => {
    const startDateMoment = moment(range.startDate).set({hours: '00', minutes: '00'});
    const startDateMomentUTC = startDateMoment.utcOffset("+00:00", true);

    const endDateMoment = moment(range.endDate).set({hours: '00', minutes: '00'});
    const endDateMomentUTC = endDateMoment.utcOffset("+00:00", true);

    const dateMoment = moment(date).set({hours: '00', minutes: '00'});
    const dateMomentUTC = dateMoment.utcOffset("+00:00", true);

    const rangeDates = moment().range(startDateMomentUTC, endDateMomentUTC);
    return rangeDates.contains(dateMomentUTC);
}

export const getUpdateReleased = () => {
    const today = moment().format(CONST.DATE_STANDARD_FORMAT);
    const todayMoment = moment(today);

    const subtractSevenDays = moment().subtract(6,'d').format('YYYY-MM-DD');
    const subtractSevenDaysMoment = moment(subtractSevenDays);

    return {
        startDate: subtractSevenDaysMoment,
        startDateInput: subtractSevenDays,
        endDate: todayMoment,
        endDateMoment: todayMoment,
    };
}

export const getCategoryValue = category => {
    return map(category, item => item.id);
}

export const parseMomentToDateString = (value, dateFormat) => {
    return value ? value.format(dateFormat) : '';
};

export const setStartWeekMonday = () => {
    moment.updateLocale('en', {
        week: {
            dow: 1,
        },
    })
};
