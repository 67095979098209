import * as types from "./constants";

export function requestInitSteamGames() {
    return {
        type: types.REQUEST_INIT_STEAM_GAMES
    };
}

export function succeedInitSteamGames(response) {
    return {
        type: types.SUCCEED_INIT_STEAM_GAMES,
        response
    };
}

export function requestSearchSteamGames(limit, offset) {
    return {
        type: types.REQUEST_SEARCH_STEAM_GAMES,
        limit, offset
    };
}

export function succeedSearchSteamGames(response) {
    return {
        type: types.SUCCEED_SEARCH_STEAM_GAMES,
        response
    };
}

export function setSteamGamesFilterData(response) {
    return {
        type: types.SET_STEAM_GAMES_FILTER_DATA,
        response
    };
}

export function setSteamSelectedLanguage(selectedLanguage) {
    return {
        type: types.SET_SELECTED_LANGUAGES_STEAM_GAMES,
        selectedLanguage
    };
}

export function setSearch(search) {
    return {
        type: types.SET_SEARCH_LANGUAGES_STEAM_GAMES,
        search
    };
}

export function setSteamGamesSelectedGenres(selectedGenres) {
    return {
        type: types.SET_SELECTED_GENRES_STEAM_GAMES,
        selectedGenres
    };
}

export function requestLastUpdate() {
    return {
        type: types.REQUEST_LAST_UPDATE_STEAM,
    };
}

export function succeedLastUpdate(lastUpdate) {
    return {
        type: types.SUCCEED_LAST_UPDATE_STEAM,
        lastUpdate
    };
}

export function setReleased(released) {
    return {
        type: types.SET_STEAM_GAMES_RELEASED,
        released
    };
}

export function setLanguagesCount(languagesCount) {
    return {
        type: types.SET_LANGUAGES_COUNT_STEAM_GAMES,
        languagesCount
    };
}

export function setIsOnlyChosenLanguages(isOnlyChosenLanguages) {
    return {
        type: types.SET_IS_ONLY_CHOSEN_LANGUAGES_STEAM_GAMES,
        isOnlyChosenLanguages
    };
}

export function setPricePlay(selectedPrice) {
    return {
        type: types.SET_PRICE_PLAY,
        selectedPrice
    };
}

export function setEarlyAccess(earlyAccess) {
    return {
        type: types.SET_EARLY_ACCESS_STEAM_GAMES,
        earlyAccess
    };
}

export function setReviews(reviews) {
    return {
        type: types.SET_REVIEWS_STEAM_GAMES,
        reviews
    };
}

export function setIsFiltersSelected(isFiltersSelected) {
    return {
        type: types.SET_IS_FILTER_SELECTED_STEAM_GAMES,
        isFiltersSelected
    };
}

export function clearFiltersSteamGames() {
    return {
        type: types.CLEAR_FILTERS_STEAM_GAMES,
    };
}

export function requestGetSteamCount() {
    return {
        type: types.REQUEST_GET_STEAM_GAMES_COUNT
    };
}

export function succeedGetSteamCount(response) {
    return {
        type: types.SUCCEED_GET_STEAM_GAMES_COUNT,
        response
    };
}

export function setTypes(steamTypes) {
    return {
        type: types.SET_TYPES_STEAM_GAMES,
        steamTypes
    };
}

