import forEach from 'lodash/forEach';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import {createPromiseClient} from "@connectrpc/connect";
import {createConnectTransport} from "@connectrpc/connect-web";
import {
    AppStoreFilter,
    AppStoreGenre,
    AppStoreLanguage,
    Comparator as ConnectRpcComparator,
    CountDevelopersAndEmailsRequest,
    CountRequest as AppStoreCountRequest,
    ExportAppStoreRequest,
    GetAppStoreRecordsRequest,
    GetFilterDataRequest as AppStoreGetFilterDataRequest,
    GetLastUpdateRequest as AppStoreGetLastUpdateRequest
} from "@alconost/kraken-appstore/src/proto/appstore/v1alpha1/app_store_pb";
import {
    CountRecordsRequest,
    DateRange as ConnectDateRange,
    ExportPlayRequest,
    GetFilterDataRequest as GooglePlayGetFilterDataRequest,
    GetLastUpdateRequest as GooglePlayGetLastUpdateRequest,
    GetPlayRecordsRequest,
    PlayMarketFilter
} from "@alconost/kraken-googleplay/src/proto/googleplay/v1alpha1/googleplay_pb";
import {
    AddBlacklistRecordRequest,
    AddMagnetUserRequest,
    ApproveContactRequest,
    BlacklistRecord,
    CommonFilter,
    DeleteBlacklistRecordRequest,
    DeleteMagnetUserRequest,
    GetBlacklistPagedRequest,
    GetContactsPagedFilter,
    GetContactsPagedRequest,
    GetMessageRequest,
    GetUsersPagedRequest,
    MagnetUser,
    RedecideContactRequest,
    RejectContactRequest,
    SkipContactRequest,
    UpdateMagnetUserRequest
} from "@alconost/kraken-magnet/src/proto/magnet/v1alpha1/magnet_pb";
import {
    CrowdinFilter,
    ExportRequest as CrowdinExportRequest,
    GetCrowdinRecordsRequest,
    GetFilterDataRequest as CrowdinGetFilterDataRequest,
    GetLastUpdateRequest as CrowdinGetLastUpdateRequest
} from "@alconost/kraken-crowdin/src/proto/crowdin/v1alpha1/crowdin_pb";
import {
    Comparator as SteamComparator,
    CountRecordsRequest as SteamCountRecordsRequest,
    ExportSteamPublishersRequest,
    ExportSteamRequest,
    GetFilterDataRequest as SteamGetFilterDataRequest,
    GetSteamLastUpdateRequest,
    GetSteamPublishersLastUpdateRequest,
    GetSteamPublishersRequest,
    GetSteamRecordsRequest,
    SteamFilter,
    SteamGenre,
    SteamPublishersFilter,
} from "@alconost/kraken-steam/src/proto/steam/v1alpha1/steam_pb";
import {
    GetCountsRequest as SubscribeGetCountsRequest,
    GetCurrentFilterRequest,
    GetListRequest,
    SubscribeFilter,
    UpdateContactRequest,
    UpdateFilterRequest,
} from "@alconost/kraken-subscribe/src/proto/subscribe/v1alpha1/subscribe_pb";
import {
    CountRequest as CrunchbaseCountRequest,
    ExportOrganizationsRequest,
    ExportSocialsRequest,
    GetFilterDataRequest as CrunchbaseGetFilterDataRequest,
    GetLastUpdateRequest as CrunchbaseGetLastUpdateRequest,
    GetOrganizationsRequest,
    IntRange,
    OrganizationFilter
} from "@alconost/kraken-crunchbase/src/proto/crunchbase/v1alpha1/crunchbase_pb";
import {
    CountRequest as SnovioCountRequest,
    ExportEmailsRequest,
    GetSnovioEmailsRequest,
    SnovioEmailsFilter
} from "@alconost/kraken-snovio/src/proto/snovio/v1alpha1/snovio_pb";
import {
    CloseFilter,
    CountRequest as CloseCountRequest,
    ExportContactsRequest,
    ExportEmailsRequest as CloseExportEmailsRequest,
    GetCloseRecordsRequest,
    GetFilterDataRequest as CloseGetFilterDataRequest,
    GetLastUpdateRequest as CloseGetLastUpdateRequest
} from "@alconost/kraken-close/src/proto/close/v1alpha1/close_pb";
import {
    CountRequest as EpicCountRequest,
    EpicFilter,
    EpicTag,
    ExportEpicRequest,
    GetEpicRecordsRequest,
    GetFilterDataRequest as EpicGetFilterDataRequest,
    GetLastUpdateRequest as EpicGetLastUpdateRequest,
    LanguageFilter
} from "@alconost/kraken-epic/src/proto/epic/v1alpha1/epic_pb";
import {
    CountRequest as HrefCountRequest,
    ExportHrefRequest,
    GetHrefRecordsRequest,
    HrefFilter
} from "@alconost/kraken-href/src/proto/href/v1alpha1/href_pb";
import {
    ExportNintendoRequest,
    NintendoAvailability,
    NintendoFilter,
    NintendoGenre,
    NintendoRequest
} from "@alconost/kraken-nintendo/src/proto/nintendo/v1alpha1/nintendo_pb";
import {
    Audience,
    CreateAudienceRequest,
    CreateDonorRequest,
    ExportRequest,
    GetAudienceByNameRequest,
    GetAudiencesFilter,
    GetAudiencesRequest,
    GetDonorsFilter,
    GetDonorsRequest,
    RemoveAudienceRequest,
    RemoveDonorRequest,
    RenameAudienceRequest,
    SendAudienceToTwitterRequest
} from "@alconost/kraken-twitter/src/proto/twitter/v1alpha1/twitter_pb";
import {GetAllHealthRequest, GetHealthRequest} from "@alconost/kraken-health/src/proto/health/v1alpha1/health_pb";
import {
    CountRequest as ItchCountRequest,
    ExportAuthorsRequest,
    ExportAuthorTwittersRequest,
    ExportGamesRequest as ItchExportGamesRequest,
    GameFilter,
    GetAuthorsRequest,
    GetFilterDataRequest as ItchGetFilterDataRequest,
    GetGamesRequest as ItchGetGamesRequest,
    GetLastUpdateRequest as ItchGetLastUpdateRequest,
} from "@alconost/kraken-itchio/src/proto/itchio/v1alpha1/itchio_pb";
import {
    CompaniesFilter,
    CountRequest as EuStartupsCountRequest,
    ExportCompanyRequest as EuStartupsExportCompanyRequest,
    ExportEmailsRequest as EuStartupsExportEmailsRequest,
    ExportTwitterRequest,
    GetCompaniesRequest,
    GetFilterDataRequest as EuStartupsGetFilterDataRequest,
    GetLastUpdateRequest as EuStartupsGetLastUpdateRequest
} from "@alconost/kraken-eustartups/src/proto/eustartups/v1alpha1/eustartups_pb";
import {
    CountRequest as GogCountRequest,
    ExportGamesRequest,
    GameFilter as GogGameFilter,
    GetFilterDataRequest as GogGetFilterDataRequest,
    GetGamesRequest,
    GetLastUpdateRequest as GogGetLastUpdateRequest,
} from "@alconost/kraken-gog/src/proto/gog/v1alpha1/gog_pb";
import {
    CompanyFilter,
    ExportPeopleRequest,
    GetEventsRequest,
    GetFilterDataRequest as MeetToMatchGetFilterDataRequest,
    GetPeopleRequest,
    PeopleFilter
} from "@alconost/kraken-meettomatch-proto/src/proto/meettomatch/v1alpha1/meettomatch_pb";

import {AppStoreService} from "@alconost/kraken-appstore/src/proto/appstore/v1alpha1/app_store_connect";
import {GooglePlayService} from "@alconost/kraken-googleplay/src/proto/googleplay/v1alpha1/googleplay_connect";
import {MagnetService} from "@alconost/kraken-magnet/src/proto/magnet/v1alpha1/magnet_connect";
import {CrowdinService} from "@alconost/kraken-crowdin/src/proto/crowdin/v1alpha1/crowdin_connect";
import {SteamService} from "@alconost/kraken-steam/src/proto/steam/v1alpha1/steam_connect";
import {SubscribeService} from "@alconost/kraken-subscribe/src/proto/subscribe/v1alpha1/subscribe_connect";
import {CrunchbaseService} from "@alconost/kraken-crunchbase/src/proto/crunchbase/v1alpha1/crunchbase_connect";
import {SnovioService} from "@alconost/kraken-snovio/src/proto/snovio/v1alpha1/snovio_connect";
import {CloseService} from "@alconost/kraken-close/src/proto/close/v1alpha1/close_connect";
import {EpicService} from "@alconost/kraken-epic/src/proto/epic/v1alpha1/epic_connect";
import {HrefService} from "@alconost/kraken-href/src/proto/href/v1alpha1/href_connect";
import {NintendoService} from "@alconost/kraken-nintendo/src/proto/nintendo/v1alpha1/nintendo_connect";
import {TwitterService} from "@alconost/kraken-twitter/src/proto/twitter/v1alpha1/twitter_connect";
import {HealthService} from "@alconost/kraken-health/src/proto/health/v1alpha1/health_connect";
import {ItchioService} from "@alconost/kraken-itchio/src/proto/itchio/v1alpha1/itchio_connect";
import {EuStartupsService} from "@alconost/kraken-eustartups/src/proto/eustartups/v1alpha1/eustartups_connect";
import {GogService} from "@alconost/kraken-gog/src/proto/gog/v1alpha1/gog_connect";
import {MeetToMatchService} from "@alconost/kraken-meettomatch-proto/src/proto/meettomatch/v1alpha1/meettomatch_connect";

import {Empty, Timestamp as ConnectRpcTimestamp} from "@bufbuild/protobuf";

import {getConnectRpcHeaders} from "./grpsUtils";
import {handleServiceError} from "../helpers/catchErrorUtils";
import {
    appStoreFieldsList,
    convertObjectToArrayOfKeys,
    getExportFieldValues,
    googlePlayFieldsList,
    removeTextInBrackets
} from "../helpers/utils";

const GOOGLE_PLAY_HOST = 'https://googleplay-n6j2kqa64a-uc.a.run.app/';
const APP_STORE_HOST = 'https://appstore-n6j2kqa64a-uc.a.run.app/';
const STEAM_HOST = 'https://steam-n6j2kqa64a-uc.a.run.app/';
const CLOSE_HOST = 'https://close-n6j2kqa64a-uc.a.run.app/';
const CROWDIN_HOST = 'https://crowdin-n6j2kqa64a-uc.a.run.app/';
const CRUNCHBASE_HOST = 'https://crunchbase-n6j2kqa64a-uc.a.run.app/';
const EPIC_HOST = 'https://epic-n6j2kqa64a-uc.a.run.app/';
const EU_STARTUPS_HOST = 'https://eustartups-n6j2kqa64a-uc.a.run.app/';
const GOG_HOST = 'https://gog-n6j2kqa64a-uc.a.run.app/';
const HEALTH_HOST = 'https://health-n6j2kqa64a-uc.a.run.app/';
const HREF_HOST = 'https://href-n6j2kqa64a-uc.a.run.app/';
const ITCH_HOST = 'https://itchio-n6j2kqa64a-uc.a.run.app/';
const NINTENDO_HOST = 'https://nintendo-n6j2kqa64a-uc.a.run.app/';
const MAGNET_HOST = 'https://magnet-n6j2kqa64a-uc.a.run.app/';
const SUBSCRIBE_HOST = 'https://subscribe-n6j2kqa64a-uc.a.run.app/';
const SNOVIO_HOST = 'https://snovio-n6j2kqa64a-uc.a.run.app/';
const TWITTER_HOST = 'https://twitter-n6j2kqa64a-uc.a.run.app/';
const MEET_TO_MATCH_HOST = 'https://meettomatch-n6j2kqa64a-uc.a.run.app/';

const googlePlayTransport = createConnectTransport({
    baseUrl: GOOGLE_PLAY_HOST,
});
const googlePlayClient = createPromiseClient(GooglePlayService, googlePlayTransport);

const appStoreTransport = createConnectTransport({
    baseUrl: APP_STORE_HOST,
});
const appStoreClient = createPromiseClient(AppStoreService, appStoreTransport);

const steamTransport = createConnectTransport({
    baseUrl: STEAM_HOST,
});
const steamClient = createPromiseClient(SteamService, steamTransport);

const closeTransport = createConnectTransport({
    baseUrl: CLOSE_HOST,
});
const closeClient = createPromiseClient(CloseService, closeTransport);

const crowdinTransport = createConnectTransport({
    baseUrl: CROWDIN_HOST,
});
const crowdinClient = createPromiseClient(CrowdinService, crowdinTransport);

const crunchbaseTransport = createConnectTransport({
    baseUrl: CRUNCHBASE_HOST,
});
const crunchbaseClient = createPromiseClient(CrunchbaseService, crunchbaseTransport);

const epicTransport = createConnectTransport({
    baseUrl: EPIC_HOST,
});
const epicClient = createPromiseClient(EpicService, epicTransport);

const euStartupsTransport = createConnectTransport({
    baseUrl: EU_STARTUPS_HOST,
});
const euStartupsClient = createPromiseClient(EuStartupsService, euStartupsTransport);

const gogTransport = createConnectTransport({
    baseUrl: GOG_HOST,
});
const gogClient = createPromiseClient(GogService, gogTransport);

const healthTransport = createConnectTransport({
    baseUrl: HEALTH_HOST,
});
const healthClient = createPromiseClient(HealthService, healthTransport);

const hrefTransport = createConnectTransport({
    baseUrl: HREF_HOST,
});
const hrefClient = createPromiseClient(HrefService, hrefTransport);

const itchTransport = createConnectTransport({
    baseUrl: ITCH_HOST,
});
const itchClient = createPromiseClient(ItchioService, itchTransport);

const nintendoTransport = createConnectTransport({
    baseUrl: NINTENDO_HOST,
});
const nintendoClient = createPromiseClient(NintendoService, nintendoTransport);

const magnetTransport = createConnectTransport({
    baseUrl: MAGNET_HOST,
});
const magnetClient = createPromiseClient(MagnetService, magnetTransport);

const subscribeTransport = createConnectTransport({
    baseUrl: SUBSCRIBE_HOST,
});
const subscribeClient = createPromiseClient(SubscribeService, subscribeTransport);

const snovioTransport = createConnectTransport({
    baseUrl: SNOVIO_HOST,
});
const snovioClient = createPromiseClient(SnovioService, snovioTransport);

const twitterTransport = createConnectTransport({
    baseUrl: TWITTER_HOST,
});
const twitterClient = createPromiseClient(TwitterService, twitterTransport);

const meetToMatchTransport = createConnectTransport({
    baseUrl: MEET_TO_MATCH_HOST,
});
const meetToMatchClient = createPromiseClient(MeetToMatchService, meetToMatchTransport);

// appStore
const getAppStoreFilter = (search, genreOptions, releasedDateRange, languages, languagesCount, reviews, score, countryList, updatedDateRange, hasVideo, countriesCount, price, appCount) => {
    const appStoreFilter = new AppStoreFilter();
    const released = new ConnectDateRange();
    const updated = new ConnectDateRange();
    const comparatorFrom = new ConnectRpcComparator();
    const comparatorTo = new ConnectRpcComparator();
    const countryComparatorFrom = new ConnectRpcComparator();
    const countryComparatorTo = new ConnectRpcComparator();

    if (languagesCount?.from || languagesCount?.to) {
        comparatorFrom.value = +languagesCount.from;
        comparatorTo.value = +languagesCount.to;
        comparatorFrom.operator = 3;
        comparatorTo.operator = 5;
        const comparatorArray = [];
        languagesCount.from && comparatorArray.push(comparatorFrom);
        languagesCount.to && comparatorArray.push(comparatorTo);
        appStoreFilter.languageCountCompare = comparatorArray;
    }

    if (countriesCount?.from || countriesCount?.to) {
        countryComparatorFrom.value = +countriesCount.from;
        countryComparatorTo.value = +countriesCount.to;
        countryComparatorFrom.operator = 3;
        countryComparatorTo.operator = 5;
        const comparatorArray = [];
        countriesCount.from && comparatorArray.push(countryComparatorFrom);
        countriesCount.to && comparatorArray.push(countryComparatorTo);
        appStoreFilter.marketsCountCompare = comparatorArray;
    }

    if (search) {
        appStoreFilter.search = search;
    }

    if (price) {
        appStoreFilter.price = price;
    }

    if (genreOptions && genreOptions.length) {
        if (genreOptions) {
            let genresArray = [];

            forEach(genreOptions, genre => {
                const appStoreGenre = new AppStoreGenre();
                appStoreGenre.id = genre.id;
                appStoreGenre.name = genre.name;
                genresArray.push(appStoreGenre);
            })

            appStoreFilter.genres = genresArray;
        }
    }
    if (releasedDateRange) {
        const fromTimestamp = new ConnectRpcTimestamp();
        const toTimestamp = new ConnectRpcTimestamp();

        fromTimestamp.seconds = releasedDateRange.from;
        fromTimestamp.nanos = 0;

        toTimestamp.seconds = releasedDateRange.to;
        toTimestamp.nanos = 0;

        released.from = fromTimestamp;
        released.to = toTimestamp;
    }
    if (updatedDateRange) {
        const fromTimestamp = new ConnectRpcTimestamp();
        const toTimestamp = new ConnectRpcTimestamp();

        fromTimestamp.seconds = updatedDateRange.from;
        fromTimestamp.nanos = 0;

        toTimestamp.seconds = updatedDateRange.to;
        toTimestamp.nanos = 0;

        updated.from = fromTimestamp;
        updated.to = toTimestamp;
    }
    if (hasVideo) {
        appStoreFilter.hasVideo = hasVideo === 'Yes';
    }
    if (languages && languages.length) {
        let languagesArray = [];

        forEach(languages, language => {
            const appStoreLanguage = new AppStoreLanguage();
            appStoreLanguage.id = language.id;
            appStoreLanguage.name = language.name;
            languagesArray.push(appStoreLanguage);
        })

        appStoreFilter.languages = languagesArray;
    }

    if (!isEmpty(reviews)) {
        const comparatorFrom = new ConnectRpcComparator();
        const comparatorTo = new ConnectRpcComparator();
        if (reviews.from === reviews.to) {
            comparatorFrom.value = +reviews.from;
            comparatorFrom.operator = 1;
            if (reviews.from) {
                appStoreFilter.reviewsCompare = [comparatorFrom];
            }
        }
        else {
            comparatorFrom.value = +reviews.from;
            comparatorTo.value = +reviews.to;
            comparatorFrom.operator = 3;
            comparatorTo.operator = 5;
            const comparatorArray = [];
            reviews.from && comparatorArray.push(comparatorFrom);
            reviews.to && comparatorArray.push(comparatorTo);
            appStoreFilter.reviewsCompare = comparatorArray;
        }
    }

    if (!isEmpty(score)) {
        const comparatorFrom = new ConnectRpcComparator();
        const comparatorTo = new ConnectRpcComparator();
        if (score.from === score.to) {
            comparatorFrom.value = +score.from;
            comparatorFrom.operator = 1;
            if (score.from) {
                appStoreFilter.scoreCompare = [comparatorFrom];
            }
        }
        else {
            comparatorFrom.value = +score.from;
            comparatorTo.value = +score.to;
            comparatorFrom.operator = 3;
            comparatorTo.operator = 5;
            const comparatorArray = [];
            score.from && comparatorArray.push(comparatorFrom);
            score.to && comparatorArray.push(comparatorTo);
            appStoreFilter.scoreCompare = comparatorArray;
        }
    }

    if (!isEmpty(appCount)) {
        const comparatorFrom = new ConnectRpcComparator();
        const comparatorTo = new ConnectRpcComparator();
        if (appCount.from === appCount.to) {
            comparatorFrom.value = +appCount.from;
            comparatorFrom.operator = 1;
            if (appCount.from) {
                appStoreFilter.appCountForDeveloper = [comparatorFrom];
            }
        }
        else {
            comparatorFrom.value = +appCount.from;
            comparatorTo.value = +appCount.to;
            comparatorFrom.operator = 3;
            comparatorTo.operator = 5;
            const comparatorArray = [];
            appCount.from && comparatorArray.push(comparatorFrom);
            appCount.to && comparatorArray.push(comparatorTo);
            appStoreFilter.appCountForDeveloper = comparatorArray;
        }
    }

    if (countryList.length) {
        appStoreFilter.countryCodes = map(countryList, country => country.id);
    }

    appStoreFilter.released = released;
    appStoreFilter.updated = updated;

    return appStoreFilter;
}

const getAppStoreRequest = (limit, offset, search, genreOptions, releasedDateRange, languages, languagesCount, reviews, score, countryList, updatedDateRange, hasVideo, countriesCount, fields, price, appCount) => {
    const appStoreRequest = new GetAppStoreRecordsRequest();

    const appStoreFilter = getAppStoreFilter(search, genreOptions, releasedDateRange, languages, languagesCount, reviews, score, countryList, updatedDateRange, hasVideo, countriesCount, price, appCount);

    appStoreRequest.limit = limit;
    appStoreRequest.offset = offset;
    appStoreRequest.filter = appStoreFilter;

    if (fields) {
        const {fieldsList, uniqueFieldsList} = fields;
        appStoreRequest.showFields = fieldsList;
        appStoreRequest.uniqueFields = uniqueFieldsList;
    }

    return appStoreRequest;
}

export const getAppStoreRecordsRequest = async (limit, offset, search, genreOptions, releasedDateRange, languages, languagesCount, reviews, score, countryList, token, updatedDateRange, hasVideo, countriesCount, price, appCount) => {
    const headers = getConnectRpcHeaders(token);

    const appStoreRequest = getAppStoreRequest(limit, offset, search, genreOptions, releasedDateRange, languages, languagesCount, reviews, score, countryList, updatedDateRange, hasVideo, countriesCount, null, price, appCount);

    return appStoreClient.getAppStoreRecords(appStoreRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportAppStoreRecordsRequest = async (limit, offset, search, genreOptions, releasedDateRange, languages, languagesCount, reviews, score, countryList, token, updatedDateRange, hasVideo, countriesCount, fields, price, appCount) => {
    const headers = getConnectRpcHeaders(token);
    const exportAppStoreRequest = new ExportAppStoreRequest();

    exportAppStoreRequest.request = getAppStoreRequest(limit, offset, search, genreOptions, releasedDateRange, languages, languagesCount, reviews, score, countryList, updatedDateRange, hasVideo, countriesCount, fields, price, appCount);

    return appStoreClient.exportAppStore(exportAppStoreRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getLastUpdateAppStoreRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getLastUpdateRequest = new AppStoreGetLastUpdateRequest();

    return appStoreClient.getLastUpdate(getLastUpdateRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getFilterDataAppStoreRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getFilterDataRequest = new AppStoreGetFilterDataRequest();

    return appStoreClient.getFilterData(getFilterDataRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getCountAppStore = async (search, genreOptions, releasedDateRange, languages, languagesCount, reviews, score, countryList, token, updatedDateRange, hasVideo, countriesCount, fields, price, appCount) => {
    const headers = getConnectRpcHeaders(token);
    const countRequest = new AppStoreCountRequest();

    countRequest.filter = getAppStoreFilter(search, genreOptions, releasedDateRange, languages, languagesCount, reviews, score, countryList, updatedDateRange, hasVideo, countriesCount, price, appCount);

    if (fields) {
        const {fieldsList, uniqueFieldsList} = fields;
        countRequest.showFields = fieldsList;
        countRequest.uniqueFields = uniqueFieldsList;
    }

    if (!isEmpty(appCount) && !fields) {
        const allFields = appStoreFieldsList.filter(field => field.id !== 'email');
        const {fieldsList} = getExportFieldValues(allFields);
        countRequest.showFields = fieldsList;
    }

    return appStoreClient.count(countRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getCountDevelopersAndEmailsAppStore = async (search, genreOptions, releasedDateRange, languages, languagesCount, reviews, score, countryList, token, updatedDateRange, hasVideo, countriesCount, fields, price, appCount) => {
    const headers = getConnectRpcHeaders(token);
    const countDevelopersAndEmailsRequest = new CountDevelopersAndEmailsRequest();

    countDevelopersAndEmailsRequest.filter = getAppStoreFilter(search, genreOptions, releasedDateRange, languages, languagesCount, reviews, score, countryList, updatedDateRange, hasVideo, countriesCount, price, appCount);

    return appStoreClient.countDevelopersAndEmails(countDevelopersAndEmailsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getAppStoreInsightsRequest = async (token, limit, offset, updatedDateRange, hasVideo, score) => {
    const headers = getConnectRpcHeaders(token);
    const appStoreRequest = new GetAppStoreRecordsRequest();
    const appStoreFilter = new AppStoreFilter();
    const updated = new ConnectDateRange();

    appStoreRequest.limit = limit;
    appStoreRequest.offset = offset;
    appStoreRequest.sortField = "score";
    appStoreRequest.sortOrder = 1;

    const fromTimestamp = new ConnectRpcTimestamp();
    const toTimestamp = new ConnectRpcTimestamp();
    fromTimestamp.seconds = updatedDateRange.from;
    fromTimestamp.nanos = 0;
    toTimestamp.seconds = updatedDateRange.to;
    toTimestamp.nanos = 0;
    updated.from = fromTimestamp;
    updated.to = toTimestamp;
    appStoreFilter.updated = updated;

    appStoreFilter.hasVideo = hasVideo === 'Yes';

    if (!isEmpty(score)) {
        const comparatorFrom = new ConnectRpcComparator();
        const comparatorTo = new ConnectRpcComparator();
        comparatorFrom.value = +score.from;
        comparatorTo.value = +score.to;
        comparatorFrom.operator = 3;
        comparatorTo.operator = 5;
        const comparatorArray = [];
        score.from && comparatorArray.push(comparatorFrom);
        score.to && comparatorArray.push(comparatorTo);
        appStoreFilter.scoreCompare = comparatorArray;
    }

    appStoreRequest.filter = appStoreFilter;

    return appStoreClient.getAppStoreRecords(appStoreRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

// googlePlay
const getPlayFilter = (category, releasedDateRange, price, countryList, downloads, search, countriesCount, updatedDateRange, hasVideo, score, appCount, categoryType) => {
    const playMarketFilter = new PlayMarketFilter();
    const released = new ConnectDateRange();
    const updated = new ConnectDateRange();
    const comparatorFrom = new ConnectRpcComparator();
    const comparatorTo = new ConnectRpcComparator();

    if (categoryType) {
        playMarketFilter.categoryType = categoryType;
    }

    if (search) {
        playMarketFilter.search = search;
    }

    if (releasedDateRange) {
        const fromTimestamp = new ConnectRpcTimestamp();
        const toTimestamp = new ConnectRpcTimestamp();

        fromTimestamp.seconds = releasedDateRange.from;
        fromTimestamp.nanos = 0;

        toTimestamp.seconds = releasedDateRange.to;
        toTimestamp.nanos = 0;

        released.from = fromTimestamp;
        released.to = toTimestamp;
    }

    if (hasVideo) {
        playMarketFilter.hasVideo = hasVideo === 'Yes';
    }

    if (updatedDateRange) {
        const fromTimestamp = new ConnectRpcTimestamp();
        const toTimestamp = new ConnectRpcTimestamp();

        fromTimestamp.seconds = updatedDateRange.from;
        fromTimestamp.nanos = 0;

        toTimestamp.seconds = updatedDateRange.to;
        toTimestamp.nanos = 0;

        updated.from = fromTimestamp;
        updated.to = toTimestamp;
    }

    if (!isEmpty(downloads)) {
        const comparatorFrom = new ConnectRpcComparator();
        const comparatorTo = new ConnectRpcComparator();
        if (downloads.from === downloads.to) {
            comparatorFrom.value = +downloads.from;
            comparatorFrom.operator = 1;
            if (downloads.from) {
                playMarketFilter.downloadsCompare = [comparatorFrom]
            }
        }
        else {
            comparatorFrom.value = +downloads.from;
            comparatorTo.value = +downloads.to;
            comparatorFrom.operator = 3;
            comparatorTo.operator = 5;
            const comparatorArray = [];
            downloads.from && comparatorArray.push(comparatorFrom);
            downloads.to && comparatorArray.push(comparatorTo);
            playMarketFilter.downloadsCompare = comparatorArray;
        }
    }

    if (!isEmpty(score)) {
        const comparatorFrom = new ConnectRpcComparator();
        const comparatorTo = new ConnectRpcComparator();
        if (score.from === score.to) {
            comparatorFrom.value = +score.from;
            comparatorFrom.operator = 1;
            if (score.from) {
                playMarketFilter.scoreCompare = [comparatorFrom];
            }
        }
        else {
            comparatorFrom.value = +score.from;
            comparatorTo.value = +score.to;
            comparatorFrom.operator = 3;
            comparatorTo.operator = 5;
            const comparatorArray = [];
            score.from && comparatorArray.push(comparatorFrom);
            score.to && comparatorArray.push(comparatorTo);
            playMarketFilter.scoreCompare = comparatorArray;
        }
    }

    if (!isEmpty(appCount)) {
        const comparatorFrom = new ConnectRpcComparator();
        const comparatorTo = new ConnectRpcComparator();
        if (appCount.from === appCount.to) {
            comparatorFrom.value = +appCount.from;
            comparatorFrom.operator = 1;
            if (appCount.from) {
                playMarketFilter.appCountCompare = [comparatorFrom];
            }
        }
        else {
            comparatorFrom.value = +appCount.from;
            comparatorTo.value = +appCount.to;
            comparatorFrom.operator = 3;
            comparatorTo.operator = 5;
            const comparatorArray = [];
            appCount.from && comparatorArray.push(comparatorFrom);
            appCount.to && comparatorArray.push(comparatorTo);
            playMarketFilter.appCountCompare = comparatorArray;
        }
    }

    if (countryList.length) {
        playMarketFilter.countries = map(countryList, country => country.id);
    }

    if (countriesCount?.from || countriesCount?.to) {
        comparatorFrom.value = +countriesCount.from;
        comparatorTo.value = +countriesCount.to;
        comparatorFrom.operator = 3;
        comparatorTo.operator = 5;
        const comparatorArray = [];
        countriesCount.from && comparatorArray.push(comparatorFrom);
        countriesCount.to && comparatorArray.push(comparatorTo);
        playMarketFilter.countryCountCompare = comparatorArray;
    }

    if (price) {
        playMarketFilter.price = price;
    }

    playMarketFilter.released = released;
    playMarketFilter.updated = updated;

    if (category.length) {
        playMarketFilter.categories = category;
    }

    return playMarketFilter;
}

const getPlayRequest = (limit, offset, category, releasedDateRange, price, countryList, downloads, search, fields, countriesCount, updatedDateRange, hasVideo, score, appCount, categoryType) => {
    const playMarketRequest = new GetPlayRecordsRequest();

    const playMarketFilter = getPlayFilter(category, releasedDateRange, price, countryList, downloads, search, countriesCount, updatedDateRange, hasVideo, score, appCount, categoryType);

    if (!fields && !isEmpty(appCount)) {
        const {fieldsList} = getExportFieldValues(googlePlayFieldsList);
        playMarketRequest.showFields = fieldsList;
    }

    if (fields) {
        const {fieldsList, uniqueFieldsList} = fields;
        playMarketRequest.showFields = fieldsList;
        playMarketRequest.uniqueFields = uniqueFieldsList;
    }

    playMarketRequest.limit = limit;
    playMarketRequest.offset = offset;
    playMarketRequest.filter = playMarketFilter;

    return playMarketRequest;
}

export const getPlayRecordsRequest = async (limit, offset, category, releasedDateRange, price, countryList, downloads, search, countriesCount,  token, updated, hasVideo, score, appCount, categoryType) => {
    const headers = getConnectRpcHeaders(token);

    const playMarketRequest = getPlayRequest(limit, offset, category, releasedDateRange, price, countryList, downloads, search, '', countriesCount, updated, hasVideo, score, appCount, categoryType);

    return googlePlayClient.getPlayRecords(playMarketRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getCountPlayRecordsRequest = async (limit, offset, category, releasedDateRange, price, countryList, downloads, search, countriesCount, token, updated, hasVideo, score, appCount, categoryType) => {
    const headers = getConnectRpcHeaders(token);
    const countRecordsRequest = new CountRecordsRequest();

    countRecordsRequest.filter = getPlayFilter(category, releasedDateRange, price, countryList, downloads, search, countriesCount, updated, hasVideo, score, appCount, categoryType);

    return googlePlayClient.countRecords(countRecordsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getLastUpdateGooglePlayRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getLastUpdateRequest = new GooglePlayGetLastUpdateRequest();

    return googlePlayClient.getLastUpdate(getLastUpdateRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getGooglePlayFilterData = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getFilterDataRequest = new GooglePlayGetFilterDataRequest();

    return googlePlayClient.getFilterData(getFilterDataRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getPlayExportCountRequest = async (limit, offset, category, releasedDateRange, price, countryList, downloads, search, token, fields, countriesCount, updated, hasVideo, score, appCount, categoryType) => {
    const headers = getConnectRpcHeaders(token);
    const countRecordsRequest = new CountRecordsRequest();

    countRecordsRequest.filter = getPlayFilter(category, releasedDateRange, price, countryList, downloads, search, countriesCount, updated, hasVideo, score, appCount, categoryType);

    if (fields) {
        const {fieldsList, uniqueFieldsList} = fields;
        countRecordsRequest.showFields = fieldsList;
        countRecordsRequest.uniqueFields = uniqueFieldsList;
    }

    return googlePlayClient.countRecords(countRecordsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportGooglePlayRequest = async (limit, offset, category, releasedDateRange, price, countryList, downloads, search, token, fields, countriesCount, updated, hasVideo, score, appCount, categoryType) => {
    const headers = getConnectRpcHeaders(token);
    const exportPlayRequest = new ExportPlayRequest();

    exportPlayRequest.request = getPlayRequest(limit, offset, category, releasedDateRange, price, countryList, downloads, search, fields, countriesCount, updated, hasVideo, score, appCount, categoryType);

    return googlePlayClient.exportPlay(exportPlayRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getPlayInsightsRequest = async (token, limit, offset, releasedDateRange, updatedDateRange, downloads, hasVideo) => {
    const headers = getConnectRpcHeaders(token);
    const playMarketRequest = new GetPlayRecordsRequest();
    const playMarketFilter = new PlayMarketFilter();
    const released = new ConnectDateRange();
    const updated = new ConnectDateRange();

    playMarketRequest.limit = limit;
    playMarketRequest.offset = offset;
    playMarketRequest.sortField = "rating";
    playMarketRequest.sortOrder = 1;

    if (releasedDateRange) {
        const fromTimestampReleased = new ConnectRpcTimestamp();
        const toTimestampReleased = new ConnectRpcTimestamp();
        fromTimestampReleased.seconds = releasedDateRange.from;
        fromTimestampReleased.nanos = 0;
        toTimestampReleased.seconds = releasedDateRange.to;
        toTimestampReleased.nanos = 0;
        released.from = fromTimestampReleased;
        released.to = toTimestampReleased;
        playMarketFilter.released = released;
    }

    if (hasVideo) {
        playMarketFilter.hasVideo = hasVideo === 'Yes';
    }

    if (updatedDateRange) {
        const fromTimestampUpdate = new ConnectRpcTimestamp();
        const toTimestampUpdate = new ConnectRpcTimestamp();
        fromTimestampUpdate.seconds = updatedDateRange.from;
        fromTimestampUpdate.nanos = 0;
        toTimestampUpdate.seconds = updatedDateRange.to;
        toTimestampUpdate.nanos = 0;
        updated.from = fromTimestampUpdate;
        updated.to = toTimestampUpdate;
        playMarketFilter.updated = updated;
    }

    if (!isEmpty(downloads)) {
        const comparatorFrom = new ConnectRpcComparator();
        const comparatorTo = new ConnectRpcComparator();
        if (downloads.from === downloads.to) {
            comparatorFrom.value = +downloads.from;
            comparatorFrom.operator = 1;
            if (downloads.from) {
                playMarketFilter.downloadsCompare = [comparatorFrom];
            }
        }
        else {
            comparatorFrom.value = +downloads.from;
            comparatorTo.value = +downloads.to;
            comparatorFrom.operator = 3;
            comparatorTo.operator = 5;
            const comparatorArray = [];
            downloads.from && comparatorArray.push(comparatorFrom);
            downloads.to && comparatorArray.push(comparatorTo);
            playMarketFilter.downloadsCompare = comparatorArray;
        }
    }

    playMarketRequest.filter = playMarketFilter;

    return googlePlayClient.getPlayRecords(playMarketRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

// magnet
export const getUsersPagedRequest = async (token, limit, offset, search, account, order, sortField) => {
    const headers = getConnectRpcHeaders(token);
    const getUsersPagedRequest = new GetUsersPagedRequest();
    const commonFilter = new CommonFilter();

    getUsersPagedRequest.limit = limit;
    getUsersPagedRequest.offset = offset;
    if (order) {
        getUsersPagedRequest.order = order;
    }
    if (sortField) {
        getUsersPagedRequest.sortField = sortField;
    }

    commonFilter.account = account;
    if (search) {
        commonFilter.search = search;
    }

    getUsersPagedRequest.filter = commonFilter;

    return magnetClient.getUsersPaged(getUsersPagedRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const addMargetUserRequest = async (token, id, account) => {
    const headers = getConnectRpcHeaders(token);
    const addMagnetUserRequest = new AddMagnetUserRequest();
    const magnetUser = new MagnetUser();

    magnetUser.id = id;
    magnetUser.account = account;

    addMagnetUserRequest.user = magnetUser;

    return magnetClient.addMagnetUser(addMagnetUserRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const deleteMargetUserRequest = async (token, id, account) => {
    const headers = getConnectRpcHeaders(token);
    const deleteMagnetUserRequest = new DeleteMagnetUserRequest();

    deleteMagnetUserRequest.id = id;
    deleteMagnetUserRequest.account = account;

    return magnetClient.deleteMagnetUser(deleteMagnetUserRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const updateMargetUserRequest = async (token, item) => {
    const headers = getConnectRpcHeaders(token);
    const updateMagnetUserRequest = new UpdateMagnetUserRequest();
    const magnetUser = new MagnetUser();

    const {id, account, checkpoint, isInbound, isOutbound, status, parseResult} = item;
    magnetUser.id = id;
    magnetUser.account = account;

    const timestamp = new ConnectRpcTimestamp();
    timestamp.seconds = checkpoint.seconds;
    timestamp.nanos = checkpoint.nanos;
    magnetUser.checkpoint = timestamp;
    magnetUser.isInbound = isInbound;
    magnetUser.isOutbound = isOutbound;
    magnetUser.status = status;
    magnetUser.parseResult = parseResult;

    updateMagnetUserRequest.user = magnetUser;

    return magnetClient.updateMagnetUser(updateMagnetUserRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const addDomainBlacklistRecordRequest = async (token, value, account) => {
    const headers = getConnectRpcHeaders(token);
    const addDomainBlacklistRecordRequest = new AddBlacklistRecordRequest();

    addDomainBlacklistRecordRequest.record = value;
    addDomainBlacklistRecordRequest.account = account;

    return magnetClient.addBlacklistRecord(addDomainBlacklistRecordRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const deleteDomainBlacklistRecordRequest = async (token, id, value, account) => {
    const headers = getConnectRpcHeaders(token);
    const deleteBlacklistRecordRequest = new DeleteBlacklistRecordRequest();
    const domainBlacklistRecord = new BlacklistRecord();

    domainBlacklistRecord.id = id;
    domainBlacklistRecord.record = value;
    domainBlacklistRecord.account = account;

    deleteBlacklistRecordRequest.record = domainBlacklistRecord;

    return magnetClient.deleteBlacklistRecord(deleteBlacklistRecordRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getDomainBlacklistPagedRequest = async (token, limit, offset, search, account) => {
    const headers = getConnectRpcHeaders(token);
    const getBlacklistPagedRequest = new GetBlacklistPagedRequest();
    const commonFilter = new CommonFilter();

    getBlacklistPagedRequest.limit = limit;
    getBlacklistPagedRequest.offset = offset;
    commonFilter.search = search;
    commonFilter.account = account;

    getBlacklistPagedRequest.filter = commonFilter;

    return magnetClient.getBlacklistPaged(getBlacklistPagedRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getContactsPagedRequest = async (token, limit, offset, search, account, status, order) => {
    const headers = getConnectRpcHeaders(token);
    const getContactsPagedRequest = new GetContactsPagedRequest();
    const getContactsPagedFilter = new GetContactsPagedFilter();

    getContactsPagedFilter.search = search;
    getContactsPagedFilter.account = account;

    if (status && status !== 'All') {
         getContactsPagedFilter.status = status;
    }
    getContactsPagedRequest.order = order;
    getContactsPagedRequest.limit = limit;
    getContactsPagedRequest.offset = offset;
    getContactsPagedRequest.filter = getContactsPagedFilter;

    return magnetClient.getContactsPaged(getContactsPagedRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const updateMagnetContactRequest = async (token, account, contact) => {
    const headers = getConnectRpcHeaders(token);
    const redecideContactRequest = new RedecideContactRequest();

    redecideContactRequest.account = account;
    redecideContactRequest.email = contact.id;

    return magnetClient.redecideContact(redecideContactRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getMagnetMessageRequest = async (token, account) => {
    const headers = getConnectRpcHeaders(token);
    const getMessageRequest = new GetMessageRequest();

    getMessageRequest.account = account;

    const stream = magnetClient.getMessage(getMessageRequest, headers);
    let response = [];

    for await (const res of stream) {
        if (res?.result?.case !== "processing") {
            response.push(res);
        }
    }

    return response;
}

export const approveContactInCloseRequest = async (token, account, email) => {
    const headers = getConnectRpcHeaders(token);
    const approveContactRequest = new ApproveContactRequest();

    approveContactRequest.account = account;
    approveContactRequest.email = email;


    return magnetClient.approveContact(approveContactRequest, headers).then(res => {
        return res;
    }).catch(err => {
        if (typeof err === "object" && err.message) {
            handleServiceError(0, err.message);
        }
        else {
            return removeTextInBrackets(err);
        }
    });
}

export const rejectMagnetContactRequest = async (token, account, email) => {
    const headers = getConnectRpcHeaders(token);
    const rejectContactRequest = new RejectContactRequest();

    rejectContactRequest.account = account;
    rejectContactRequest.email = email;

    return magnetClient.rejectContact(rejectContactRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const skipMagnetContactRequest = async (token, account, email) => {
    const headers = getConnectRpcHeaders(token);
    const skipContactRequest = new SkipContactRequest();

    skipContactRequest.account = account;
    skipContactRequest.email = email;

    return magnetClient.skipContact(skipContactRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

// crowdin
const getCrowdinFilter = (releasedDateRange, search, targetLanguagesCount, projectTypes, lastActivityDateRange) => {
    const crowdinFilter = new CrowdinFilter();
    const released = new ConnectDateRange();
    const lastActivity = new ConnectDateRange();

    if (search) {
        crowdinFilter.search = search;
    }

    if (releasedDateRange) {
        const fromTimestamp = new ConnectRpcTimestamp();
        const toTimestamp = new ConnectRpcTimestamp();

        fromTimestamp.seconds = releasedDateRange.from;
        fromTimestamp.nanos = 0;

        toTimestamp.seconds = releasedDateRange.to;
        toTimestamp.nanos = 0;

        released.from = fromTimestamp;
        released.to = toTimestamp;
    }

    if (lastActivityDateRange) {
        const fromTimestamp = new ConnectRpcTimestamp();
        const toTimestamp = new ConnectRpcTimestamp();

        fromTimestamp.seconds = lastActivityDateRange.from;
        fromTimestamp.nanos = 0;

        toTimestamp.seconds = lastActivityDateRange.to;
        toTimestamp.nanos = 0;

        lastActivity.from = fromTimestamp;
        lastActivity.to = toTimestamp;
    }

    if (targetLanguagesCount) {
        crowdinFilter.languagesCount = +targetLanguagesCount;
    }

    if (projectTypes?.length) {
        crowdinFilter.projectType = map(projectTypes, type => type.id);
    }

    crowdinFilter.created = released;
    crowdinFilter.lastActivity = lastActivity;

    return crowdinFilter;
}

export const getCrowdinRecordsRequest = async (token, limit, offset, releasedDateRange, search, targetLanguagesCount, projectTypes, lastActivityDateRange) => {
    const headers = getConnectRpcHeaders(token);
    const getCrowdinRecordsRequest = new GetCrowdinRecordsRequest();

    const crowdinFilter = getCrowdinFilter(releasedDateRange, search, targetLanguagesCount, projectTypes, lastActivityDateRange);

    getCrowdinRecordsRequest.limit = limit;
    getCrowdinRecordsRequest.offset = offset;

    getCrowdinRecordsRequest.filter = crowdinFilter;


    return crowdinClient.getCrowdinRecords(getCrowdinRecordsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getLastUpdateCrowdinRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getLastUpdateRequest = new CrowdinGetLastUpdateRequest();

    return crowdinClient.getLastUpdate(getLastUpdateRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportCrowdinRequest = async (token, releasedDateRange, search, targetLanguagesCount, projectTypes, lastActivityDateRange) => {
    const headers = getConnectRpcHeaders(token);
    const exportRequest = new CrowdinExportRequest();

    exportRequest.filter = getCrowdinFilter(releasedDateRange, search, targetLanguagesCount, projectTypes, lastActivityDateRange);

    return crowdinClient.export(exportRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getFilterDataCrowdinRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getFilterDataRequest = new CrowdinGetFilterDataRequest();

    return crowdinClient.getFilterData(getFilterDataRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

// steam

const getSteamFilter = (languagesList, genresList, search, languagesCount, isDistinctEmails, releasedDateRange, price, earlyAccess, reviews, types) => {
    const steamFilter = new SteamFilter();
    const released = new ConnectDateRange();
    const comparatorFrom = new SteamComparator();
    const comparatorTo = new SteamComparator();

    if (languagesCount?.from || languagesCount?.to) {
        comparatorFrom.value = +languagesCount.from;
        comparatorTo.value = +languagesCount.to;
        comparatorFrom.operator = 3;
        comparatorTo.operator = 5;
        const comparatorArray = [];
        languagesCount.from && comparatorArray.push(comparatorFrom);
        languagesCount.to && comparatorArray.push(comparatorTo);
        steamFilter.languageCountCompare = comparatorArray;
    }

    if (!isEmpty(reviews)) {
        const comparatorFrom = new SteamComparator();
        const comparatorTo = new SteamComparator();
        if (reviews.from === reviews.to) {
            comparatorFrom.value = +reviews.from;
            comparatorFrom.operator = 1;
            if (reviews.from) {
                steamFilter.reviewsCompare = [comparatorFrom];
            }
        }
        else {
            comparatorFrom.value = +reviews.from;
            comparatorTo.value = +reviews.to;
            comparatorFrom.operator = 3;
            comparatorTo.operator = 5;
            const comparatorArray = [];
            reviews.from && comparatorArray.push(comparatorFrom);
            reviews.to && comparatorArray.push(comparatorTo);
            steamFilter.reviewsCompare = comparatorArray;
        }
    }

    if (price) {
        steamFilter.price = price;
    }
    if (earlyAccess) {
        steamFilter.earlyAccess = earlyAccess;
    }
    if (search) {
        steamFilter.search = search;
    }

    if (releasedDateRange) {
        const fromTimestamp = new ConnectRpcTimestamp();
        const toTimestamp = new ConnectRpcTimestamp();

        fromTimestamp.seconds = releasedDateRange.from;
        fromTimestamp.nanos = 0;

        toTimestamp.seconds = releasedDateRange.to;
        toTimestamp.nanos = 0;

        released.from = fromTimestamp;
        released.to = toTimestamp;

        steamFilter.releaseDate = released;
    }

    if (languagesList?.length) {
        steamFilter.languageIds = languagesList;
    }

    if (genresList?.length) {
        let genresArray = [];

        forEach(genresList, genre => {
            const steamGenre = new SteamGenre();
            steamGenre.id = genre.id;
            steamGenre.name = genre.name;
            genresArray.push(steamGenre);
        })

        steamFilter.genres = genresArray;
    }

    if (types?.length) {
        let typesArray = [];

        forEach(types, type => {
            typesArray.push(type.id);
        })

        steamFilter.types = typesArray;
    }

    steamFilter.isDistinctEmails = isDistinctEmails;

    return steamFilter;
}

const getSteamRequest = (limit, offset, languagesList, genresList, search, languagesCount, isDistinctEmails, releasedDateRange, price, earlyAccess, reviews, types, fields) => {
    const steamRequest = new GetSteamRecordsRequest();

    const steamFilter = getSteamFilter(languagesList, genresList, search, languagesCount, isDistinctEmails, releasedDateRange, price, earlyAccess, reviews, types);

    if (fields) {
        const {fieldsList, uniqueFieldsList} = fields;
        steamRequest.showFields = fieldsList;
        steamRequest.uniqueFields = uniqueFieldsList;
    }

    steamRequest.limit = limit;
    steamRequest.offset = offset;
    steamRequest.filter = steamFilter;

    return steamRequest;
}

const getSteamPublishersRequest = (limit, offset, search, releasedDateRange, productCount) => {
    const steamPublishersRequest = new GetSteamPublishersRequest();
    const steamPublishersFilter = new SteamPublishersFilter();
    const released = new ConnectDateRange();

    steamPublishersRequest.limit = limit;
    steamPublishersRequest.offset = offset;

    steamPublishersFilter.search = search;
    if (releasedDateRange) {
        const fromTimestamp = new ConnectRpcTimestamp();
        const toTimestamp = new ConnectRpcTimestamp();

        fromTimestamp.seconds = releasedDateRange.from;
        fromTimestamp.nanos = 0;

        toTimestamp.seconds = releasedDateRange.to;
        toTimestamp.nanos = 0;

        released.from = fromTimestamp;
        released.to = toTimestamp;
    }

    if (!isEmpty(productCount)) {
        const comparatorFrom = new SteamComparator();
        const comparatorTo = new SteamComparator();
        if (productCount.from === productCount.to) {
            comparatorFrom.value = +productCount.from;
            comparatorFrom.operator = 1;
            if (productCount.from) {
                steamPublishersFilter.productCountCompare = [comparatorFrom];
            }
        }
        else {
            comparatorFrom.value = +productCount.from;
            comparatorTo.value = +productCount.to;
            comparatorFrom.operator = 3;
            comparatorTo.operator = 5;
            const comparatorArray = [];
            productCount.from && comparatorArray.push(comparatorFrom);
            productCount.to && comparatorArray.push(comparatorTo);
            steamPublishersFilter.productCountCompare = comparatorArray;
        }
    }

    steamPublishersFilter.created = released;
    steamPublishersRequest.filter = steamPublishersFilter;

    return steamPublishersRequest;
}

export const getSteamRecordsRequest = async (token, limit, offset, languagesList, genresList, search, languagesCount, isDistinctEmails, releasedDateRange, price, earlyAccess, reviews, types) => {
    const headers = getConnectRpcHeaders(token);

    const steamRequest = getSteamRequest(limit, offset, languagesList, genresList, search, languagesCount, isDistinctEmails, releasedDateRange, price, earlyAccess, reviews, types);

    return steamClient.getSteamRecords(steamRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getLastUpdateSteamRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getSteamLastUpdateRequest = new GetSteamLastUpdateRequest();

    return steamClient.getSteamLastUpdate(getSteamLastUpdateRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getSteamFilterData = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getFilterDataRequest = new SteamGetFilterDataRequest();

    return steamClient.getFilterData(getFilterDataRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const steamPublishersRequest = async (token, limit, offset, search, releasedDateRange, productCount) => {
    const headers = getConnectRpcHeaders(token);

    const steamPublishersRequest = getSteamPublishersRequest(limit, offset, search, releasedDateRange, productCount);

    return steamClient.getSteamPublishers(steamPublishersRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getLastUpdateSteamPublishersRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getSteamPublishersLastUpdateRequest = new GetSteamPublishersLastUpdateRequest();

    return steamClient.getSteamPublishersLastUpdate(getSteamPublishersLastUpdateRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportSteamPublishersRequest = async (token, limit, offset, search, releasedDateRange, productCount) => {
    const headers = getConnectRpcHeaders(token);
    const exportSteamPublishersRequest = new ExportSteamPublishersRequest();

    exportSteamPublishersRequest.request = getSteamPublishersRequest(limit, offset, search, releasedDateRange, productCount);

    return steamClient.exportSteamPublishers(exportSteamPublishersRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportSteamGamesRecordsRequest = async (token, limit, offset, languagesList, genresList, search, languagesCount, isDistinctEmails, releasedDateRange, price, earlyAccess, reviews, fields, types) => {
    const headers = getConnectRpcHeaders(token);
    const exportSteamRequest = new ExportSteamRequest();

    exportSteamRequest.request = getSteamRequest(limit, offset, languagesList, genresList, search, languagesCount, isDistinctEmails, releasedDateRange, price, earlyAccess, reviews, types, fields);

    return steamClient.exportSteam(exportSteamRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getSteamCountRequest = async (token, limit, offset, languagesList, genresList, search, languagesCount, isDistinctEmails, releasedDateRange, price, earlyAccess, reviews, fields, types) => {
    const headers = getConnectRpcHeaders(token);
    const countRecordsRequest = new SteamCountRecordsRequest();

    countRecordsRequest.filter = getSteamFilter(languagesList, genresList, search, languagesCount, isDistinctEmails, releasedDateRange, price, earlyAccess, reviews, types);

    if (fields) {
        const {fieldsList, uniqueFieldsList} = fields;
        countRecordsRequest.showFields = fieldsList;
        countRecordsRequest.uniqueFields = uniqueFieldsList;
    }

    return steamClient.countRecords(countRecordsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

// Subscribe

export const getSubscribeDecidedRequest = async (token, dispatch, updateProcessingMessage) => {
    const headers = getConnectRpcHeaders(token);
    const empty = new Empty();

    const stream = subscribeClient.getDecidedRecord(empty, headers);

    let response = null;
    const noMoreRecordsMessage = "No more records found";
    for await (const res of stream) {
        const {result} = res;
        if (result.value.processing === noMoreRecordsMessage || result.case === "record") {
            response = result;
        }
        else if (result.case === 'processing') {
            dispatch(updateProcessingMessage(result.value.processing));
        }
        else {
            handleServiceError(2);
        }
    }

    return response;
}

export const updateSubscribeContactRequest = async (token, id, field, type, value) => {
    const headers = getConnectRpcHeaders(token);
    const updateContactRequest = new UpdateContactRequest();

    updateContactRequest.id = id;
    updateContactRequest.field = field;

    if (type === 'textarea') {
        updateContactRequest.value = {case: "str", value};
    } else if (type === 'date') {
        const timestamp = new ConnectRpcTimestamp();
        timestamp.seconds = value;
        timestamp.nanos = 0;
        updateContactRequest.value = {case: "time", value: timestamp};
    } else if (type === 'bool') {
        updateContactRequest.value = {case: "boolean", value};
    } else if (type === 'null') {
        const empty = new Empty();
        updateContactRequest.value = {case: "null", value: empty};
    }

    return subscribeClient.updateContact(updateContactRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getListSubscribeRequest = async (token, limit, offset, search, sortField, sortOrder, selectedDecisionStatus) => {
    const headers = getConnectRpcHeaders(token);
    const listRequest = new GetListRequest();
    const subscribeFilter = new SubscribeFilter();

    if (!!search) {
        subscribeFilter.name = search;
    }

    if (selectedDecisionStatus) {
        subscribeFilter.decisionStatus = selectedDecisionStatus;
    }

    listRequest.limit = limit;
    listRequest.offset = offset;
    listRequest.filter = subscribeFilter;
    listRequest.field = sortField;
    listRequest.order = sortOrder;

    return subscribeClient.getList(listRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getSubscribeCountsRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getCountsRequest = new SubscribeGetCountsRequest();

    return subscribeClient.getCounts(getCountsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getSubscribeCurrentFilterRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getCurrentFilterRequest = new GetCurrentFilterRequest();

    return subscribeClient.getCurrentFilter(getCurrentFilterRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const updateSubscribeFilterRequest = async (token, closeFilter) => {
    const headers = getConnectRpcHeaders(token);
    const filter = new UpdateFilterRequest();

    filter.leadSearchQuery = closeFilter;

    return subscribeClient.updateFilter(filter, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

// crunchbase
const getCrunchbaseFilter = (projectTypes, selectedLocation, rank, createdDateRange) => {
    const organizationFilter = new OrganizationFilter();
    const intRange = new IntRange();
    const created = new ConnectDateRange();

    if (projectTypes?.length) {
        organizationFilter.facets = map(projectTypes, type => type.id);
    }

    if (selectedLocation && selectedLocation !== '') {
        organizationFilter.location = selectedLocation;
    }

    if (rank && (rank.from || rank.to)) {
        intRange.from = +rank.from;
        intRange.to = +rank.to;

        organizationFilter.rankOrg = intRange;
    }

    if (createdDateRange) {
        const fromTimestamp = new ConnectRpcTimestamp();
        const toTimestamp = new ConnectRpcTimestamp();

        fromTimestamp.seconds = createdDateRange.from;
        fromTimestamp.nanos = 0;

        toTimestamp.seconds = createdDateRange.to;
        toTimestamp.nanos = 0;

        created.from = fromTimestamp;
        created.to = toTimestamp;

        organizationFilter.createdAt = created;
    }

    return organizationFilter;
}

export const getCrunchbaseOrganizationsRequest = (limit, offset, projectTypes, selectedLocation, rank, createdDateRange) => {
    const getOrganizationsRequest = new GetOrganizationsRequest();
    getOrganizationsRequest.limit = limit;
    getOrganizationsRequest.offset = offset;

    getOrganizationsRequest.filter = getCrunchbaseFilter(projectTypes, selectedLocation, rank, createdDateRange);

    return getOrganizationsRequest;
}

export const getCrunchBaseRecordsRequest = async (token, limit, offset, projectTypes, selectedLocation, rank, created) => {
    const headers = getConnectRpcHeaders(token);

    const organizationsRequest = getCrunchbaseOrganizationsRequest(limit, offset, projectTypes, selectedLocation, rank, created);

    return crunchbaseClient.getOrganizations(organizationsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getCrunchbaseFilterData = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const filterData = new CrunchbaseGetFilterDataRequest();

    return crunchbaseClient.getFilterData(filterData, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const exportOrganizationsRequest = async (token, limit, offset, projectTypes, selectedLocation, rank, created) => {
    const headers = getConnectRpcHeaders(token);
    const exportOrganizationsRequest = new ExportOrganizationsRequest();

    exportOrganizationsRequest.request = getCrunchbaseOrganizationsRequest(limit, offset, projectTypes, selectedLocation, rank, created);

    return crunchbaseClient.exportOrganizations(exportOrganizationsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const exportSocialsRequest = async (token, social, limit, offset, projectTypes, selectedLocation, rank, created) => {
    const headers = getConnectRpcHeaders(token);
    const exportSocialsRequest = new ExportSocialsRequest();

    const organizationsRequest = getCrunchbaseOrganizationsRequest(limit, offset, projectTypes, selectedLocation, rank, created);

    exportSocialsRequest.socialType = social;
    exportSocialsRequest.request = organizationsRequest;

    return crunchbaseClient.exportSocials(exportSocialsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getlastUpdateCrunchBaseRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getLastUpdateRequest = new CrunchbaseGetLastUpdateRequest();

    return crunchbaseClient.getLastUpdate(getLastUpdateRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getCountCrunchbase = async (token, projectTypes, selectedLocation, rank, createdDateRange) => {
    const headers = getConnectRpcHeaders(token);
    const countRequest = new CrunchbaseCountRequest();

    countRequest.filter = getCrunchbaseFilter(projectTypes, selectedLocation, rank, createdDateRange);

    return crunchbaseClient.count(countRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}
// snovio
const getSnovioRequest = (limit, offset, source, fields) => {
    const getSnovioEmailsRequest = new GetSnovioEmailsRequest();
    const snovioEmailsFilter = new SnovioEmailsFilter();

    getSnovioEmailsRequest.limit = limit;
    getSnovioEmailsRequest.offset = offset;

    if (source && source !== 'All') {
        snovioEmailsFilter.source = source;
    }

    if (fields) {
        const {fieldsList, uniqueFieldsList} = fields;
        getSnovioEmailsRequest.showFields = fieldsList;
        getSnovioEmailsRequest.uniqueFields = uniqueFieldsList;
    }

    getSnovioEmailsRequest.filter = snovioEmailsFilter;

    return getSnovioEmailsRequest;
}

export const getSnovioEmailsRequest = async (token, limit, offset, source) => {
    const headers = getConnectRpcHeaders(token);
    const snovioEmailsRequest = getSnovioRequest(limit, offset, source);

    return snovioClient.getSnovioEmails(snovioEmailsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getSnovioCountRequest = async (token, source, fields) => {
    const headers = getConnectRpcHeaders(token);
    const snovioEmailsFilter = new SnovioEmailsFilter();
    const countRequest = new SnovioCountRequest();

    if (source && source !== 'All') {
        snovioEmailsFilter.source = source;
    }

    if (fields) {
        const {fieldsList, uniqueFieldsList} = fields;
        countRequest.showFields = fieldsList;
        countRequest.uniqueFields = uniqueFieldsList;
    }

    countRequest.filter = snovioEmailsFilter;

    return snovioClient.count(countRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getSnovioFilterDataRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const empty = new Empty();

    return snovioClient.getFilterData(empty, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getLastUpdateSnovioRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const empty = new Empty();

    return snovioClient.getLastUpdate(empty, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportSnovioEmailsRequest = async (token, limit, offset, source, fields) => {
    const headers = getConnectRpcHeaders(token);
    const exportEmailsRequest = new ExportEmailsRequest();

    exportEmailsRequest.request = getSnovioRequest(limit, offset, source, fields);

    return snovioClient.exportEmails(exportEmailsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return {};
    });
};

// close
const getCloseFilter = (search, category, events) => {
    const closeFilter = new CloseFilter();
    if (search) {
        closeFilter.search = search;
    }

    if (category?.length) {
        closeFilter.categories = map(category, item => item.id);
    }

    if (events?.length) {
        closeFilter.event = events[0].id;
    }

    return closeFilter;
}

export const getCloseIoRequest = (limit, offset, search, category, events) => {
    const closeRequest = new GetCloseRecordsRequest();
    closeRequest.limit = limit;
    closeRequest.offset = offset;

    closeRequest.filter = getCloseFilter(search, category, events);

    return closeRequest;
}

export const getCloseRecordsRequest = async (token, limit, offset, search, category, events) => {
    const headers = getConnectRpcHeaders(token);

    const closeRequest = getCloseIoRequest(limit, offset, search, category, events);

    return closeClient.getCloseRecords(closeRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportCloseRequest = async (token, limit, offset, search, category, events) => {
    const headers = getConnectRpcHeaders(token);
    const exportContactsRequest = new ExportContactsRequest();

    exportContactsRequest.filter = getCloseFilter(search, category, events);

    return closeClient.exportContacts(exportContactsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportCloseEmailsRequest = async (token, search, category, events) => {
    const headers = getConnectRpcHeaders(token);
    const exportEmailsRequest = new CloseExportEmailsRequest();

    exportEmailsRequest.filter = getCloseFilter(search, category, events);

    return closeClient.exportEmails(exportEmailsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getCloseLastUpdateRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getLastUpdateRequest = new CloseGetLastUpdateRequest();

    return closeClient.getLastUpdate(getLastUpdateRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getCloseFilterDataRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getFilterDataRequest = new CloseGetFilterDataRequest();

    return closeClient.getFilterData(getFilterDataRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getCountClose = (token, search, category, events) => {
    const headers = getConnectRpcHeaders(token);
    const countRequest = new CloseCountRequest();

    countRequest.filter = getCloseFilter(search, category, events);

    return closeClient.count(countRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

// epic

const getEpicFilter = (tagsList, releasedDateRange, audioLanguagesList, textLanguagesList, audioLanguagesCount, textLanguagesCount, price, search) => {
    const epicFilter = new EpicFilter();
    const released = new ConnectDateRange();
    const languageAudioFilter = new LanguageFilter();
    const languageTextFilter = new LanguageFilter();
    const epicAudioComparatorFrom = new ConnectRpcComparator();
    const epicAudioComparatorTo = new ConnectRpcComparator();
    const epicTextComparatorFrom = new ConnectRpcComparator();
    const epicTextComparatorTo = new ConnectRpcComparator();

    if (tagsList.length) {
        let tagsArray = [];

        forEach(tagsList, tag => {
            const epicTag = new EpicTag();
            epicTag.id = tag.id;
            epicTag.name = tag.name;
            tagsArray.push(epicTag);
        })

        epicFilter.tags = tagsArray;
    }

    if (releasedDateRange) {
        const fromTimestamp = new ConnectRpcTimestamp();
        const toTimestamp = new ConnectRpcTimestamp();

        fromTimestamp.seconds = releasedDateRange.from;
        fromTimestamp.nanos = 0;

        toTimestamp.seconds = releasedDateRange.to;
        toTimestamp.nanos = 0;

        released.from = fromTimestamp;
        released.to = toTimestamp;

        epicFilter.released = released;
    }

    if (audioLanguagesList.length) {
        languageAudioFilter.languageIds = audioLanguagesList;
    }
    if (textLanguagesList.length) {
        languageTextFilter.languageIds = textLanguagesList;
    }

    if (audioLanguagesCount?.from || audioLanguagesCount?.to) {
        epicAudioComparatorFrom.value = +audioLanguagesCount.from;
        epicAudioComparatorTo.value = +audioLanguagesCount.to;
        epicAudioComparatorFrom.operator = 3;
        epicAudioComparatorTo.operator = 5;
        const comparatorArray = [];
        if (audioLanguagesCount.from) {
            comparatorArray.push(epicAudioComparatorFrom);
        }
        if (audioLanguagesCount.to) {
            comparatorArray.push(epicAudioComparatorTo);
        }
        languageAudioFilter.languageCountCompare = comparatorArray;
    }

    if (textLanguagesCount?.from || textLanguagesCount?.to) {
        epicTextComparatorFrom.value = +textLanguagesCount.from;
        epicTextComparatorTo.value = +textLanguagesCount.to;
        epicTextComparatorFrom.operator = 3;
        epicTextComparatorTo.operator = 5;
        const comparatorArray = [];
        if (textLanguagesCount.from) {
            comparatorArray.push(epicTextComparatorFrom);
        }
        if (textLanguagesCount.to) {
            comparatorArray.push(epicTextComparatorTo);
        }
        languageTextFilter.languageCountCompare = comparatorArray;
    }

    languageAudioFilter.languageType = 1;
    languageTextFilter.languageType = 2;
    epicFilter.languageFilters = [languageAudioFilter, languageTextFilter];

    if (price) {
        epicFilter.price = price;
    }

    if (search) {
        epicFilter.search = search;
    }

    return epicFilter;
}

const getEpicRequest = (limit, offset, tagsList, releasedDateRange, audioLanguagesList, textLanguagesList, audioLanguagesCount, textLanguagesCount, price, search) => {
    const epicRequest = new GetEpicRecordsRequest();
    epicRequest.limit = limit;
    epicRequest.offset = offset;

    epicRequest.filter = getEpicFilter(tagsList, releasedDateRange, audioLanguagesList, textLanguagesList, audioLanguagesCount, textLanguagesCount, price, search);

    return epicRequest;
}

export const getEpicRecordsRequest = async (token, limit, offset, tagsList, releasedDateRange, audioLanguagesList, textLanguagesList, audioLanguagesCount, textLanguagesCount, price, search) => {
    const headers = getConnectRpcHeaders(token);

    const epicRequest = getEpicRequest(limit, offset, tagsList, releasedDateRange, audioLanguagesList, textLanguagesList, audioLanguagesCount, textLanguagesCount, price, search);

    return epicClient.getEpicRecords(epicRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getLastUpdateEpicRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getLastUpdateRequest = new EpicGetLastUpdateRequest();

    return epicClient.getLastUpdate(getLastUpdateRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getEpicFilterDataRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getFilterDataRequest = new EpicGetFilterDataRequest();

    return epicClient.getFilterData(getFilterDataRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const exportEpicRecordsRequest = async (token, limit, offset, tagsList, releasedDateRange, audioLanguagesList, textLanguagesList, audioLanguagesCount, textLanguagesCount, price, search) => {
    const headers = getConnectRpcHeaders(token);
    const exportEpicRequest = new ExportEpicRequest();

    exportEpicRequest.request = getEpicRequest(limit, offset, tagsList, releasedDateRange, audioLanguagesList, textLanguagesList, audioLanguagesCount, textLanguagesCount, price, search);

    return epicClient.exportEpic(exportEpicRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getCountEpic = async (token, tagsList, releasedDateRange, audioLanguagesList, textLanguagesList, audioLanguagesCount, textLanguagesCount, price, search) => {
    const headers = getConnectRpcHeaders(token);
    const countRequest = new EpicCountRequest();

    countRequest.filter = getEpicFilter(tagsList, releasedDateRange, audioLanguagesList, textLanguagesList, audioLanguagesCount, textLanguagesCount, price, search);

    return epicClient.count(countRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

// href

const getHrefFilter = (sourceList, languagesList, languagesCount, search) => {
    const hrefFilter = new HrefFilter();
    const comparatorFrom = new ConnectRpcComparator();
    const comparatorTo = new ConnectRpcComparator();

    if (languagesCount?.from || languagesCount?.to) {
        comparatorFrom.value = +languagesCount.from;
        comparatorTo.value = +languagesCount.to;
        comparatorFrom.operator = 3;
        comparatorTo.operator = 5;
        const comparatorArray = [];
        languagesCount.from && comparatorArray.push(comparatorFrom);
        languagesCount.to && comparatorArray.push(comparatorTo);
        hrefFilter.languageCountCompare = comparatorArray;
    }

    if (sourceList.length) {
        hrefFilter.source = sourceList;
    }

    if (languagesList?.length) {
        hrefFilter.languages = languagesList;
    }

    if (search) {
        hrefFilter.search = search;
    }

    return hrefFilter;
}

export const getHrefRequest = (limit, offset, sourceList, languagesList, languagesCount, search, fields) => {
    const hrefRequest = new GetHrefRecordsRequest();
    hrefRequest.limit = limit;
    hrefRequest.offset = offset;

    if (fields) {
        const {fieldsList, uniqueFieldsList} = fields;
        hrefRequest.showFields = fieldsList;
        hrefRequest.uniqueFields = uniqueFieldsList;
    }

    hrefRequest.filter = getHrefFilter(sourceList, languagesList, languagesCount, search);

    return hrefRequest;
}

export const getHrefRecordsRequest = async (token, limit, offset, sourceList, languagesList, languagesCount, search) => {
    const headers = getConnectRpcHeaders(token);

    const hrefRequest = getHrefRequest(limit, offset, sourceList, languagesList, languagesCount, search);

    return hrefClient.getHrefRecords(hrefRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getHrefLastUpdateRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const empty = new Empty();

    return hrefClient.getLastUpdate(empty, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getHrefFilterDataRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const empty = new Empty();

    return hrefClient.getFilterData(empty, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const exportHrefRecordsRequest = async (token, limit, offset, sourceList, languagesList, languagesCount, search, fields) => {
    const headers = getConnectRpcHeaders(token);
    const exportHrefRequest = new ExportHrefRequest();

    exportHrefRequest.request = getHrefRequest(limit, offset, sourceList, languagesList, languagesCount, search, fields);

    return hrefClient.exportHref(exportHrefRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getCountHref = async (token, sourceList, languagesList, languagesCount, search, fields) => {
    const headers = getConnectRpcHeaders(token);
    const countRequest = new HrefCountRequest();

    if (fields) {
        const {fieldsList, uniqueFieldsList} = fields;
        countRequest.showFields = fieldsList;
        countRequest.uniqueFields = uniqueFieldsList;
    }

    countRequest.filter = getHrefFilter(sourceList, languagesList, languagesCount, search);

    return hrefClient.count(countRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

export const getCountEmailsAndTwittersHref  = async (token, sourceList, languagesList, languagesCount, search) => {
    const headers = getConnectRpcHeaders(token);
    const countEmailsAndTwittersRequest = new HrefCountRequest();
    countEmailsAndTwittersRequest.filter = getHrefFilter(sourceList, languagesList, languagesCount, search);
    return hrefClient.countEmailsAndTwitters(countEmailsAndTwittersRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
}

// nintendo

const getNintendoRequest = (limit, offset, languagesList, languagesCount, releasedDateRange, search, genreOptions, availabilityOptions) => {
    const nintendoRequest = new NintendoRequest();
    const nintendoFilter = new NintendoFilter();
    const dataRange = new ConnectDateRange();
    const comparatorFrom = new ConnectRpcComparator();
    const comparatorTo = new ConnectRpcComparator();

    if (languagesCount?.from || languagesCount?.to) {
        comparatorFrom.value = +languagesCount.from;
        comparatorTo.value = +languagesCount.to;
        comparatorFrom.operator = 3;
        comparatorTo.operator = 5;
        const comparatorArray = [];
        languagesCount.from && comparatorArray.push(comparatorFrom);
        languagesCount.to && comparatorArray.push(comparatorTo);
        nintendoFilter.languageCountCompare = comparatorArray;
    }

    nintendoRequest.limit = limit;
    nintendoRequest.offset = offset;

    if (languagesList?.length) {
        nintendoFilter.languageIds = languagesList;
    }

    if (releasedDateRange) {
        const fromTimestamp = new ConnectRpcTimestamp();
        const toTimestamp = new ConnectRpcTimestamp();

        fromTimestamp.seconds = releasedDateRange.from;
        fromTimestamp.nanos = 0;

        toTimestamp.seconds = releasedDateRange.to;
        toTimestamp.nanos = 0;

        dataRange.from = fromTimestamp;
        dataRange.to = toTimestamp;

        nintendoFilter.released = dataRange;
    }

    if (search) {
        nintendoFilter.search = search;
    }

    if (genreOptions?.length) {
        if (genreOptions) {
            let genresArray = [];

            forEach(genreOptions, genre => {
                const nintendoGenre = new NintendoGenre();
                nintendoGenre.id = genre.id;
                nintendoGenre.name = genre.name;
                genresArray.push(nintendoGenre);
            })

            nintendoFilter.genres = genresArray;
        }
    }

    if (availabilityOptions?.length) {
        if (availabilityOptions) {
            let availabilitiesArray = [];

            forEach(availabilityOptions, availability => {
                const nintendoAvailability = new NintendoAvailability();
                nintendoAvailability.id = availability.id;
                nintendoAvailability.name = availability.name;
                availabilitiesArray.push(nintendoAvailability);
            })

            nintendoFilter.availability = availabilitiesArray;
        }
    }

    nintendoRequest.filter = nintendoFilter;

    return nintendoRequest;
}

export const getNintendoRecordsRequest = async (token, limit, offset, languagesList, languagesCount, releasedDateRange, search, genreOptions, availabilityOptions) => {
    const headers = getConnectRpcHeaders(token);

    const nintendoRequest = getNintendoRequest(limit, offset, languagesList, languagesCount, releasedDateRange, search, genreOptions, availabilityOptions);

    return nintendoClient.getNintendoRecords(nintendoRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getLastUpdateNintendoRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const empty = new Empty();

    return nintendoClient.getLastUpdate(empty, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getNintendoFilterDataRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const empty = new Empty();

    return nintendoClient.getFilterData(empty, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportNintendoRecordsRequest = async (token, limit, offset, languagesList, languagesCount, releasedDateRange, search, genreOptions, availabilityOptions) => {
    const headers = getConnectRpcHeaders(token);
    const exportNintendoRequest = new ExportNintendoRequest();

    exportNintendoRequest.request = getNintendoRequest(limit, offset, languagesList, languagesCount, releasedDateRange, search, genreOptions, availabilityOptions);

    return nintendoClient.exportNintendo(exportNintendoRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

// twitter

export const getTwitterAudiencesRequest = async (token, limit, offset, search) => {
    const headers = getConnectRpcHeaders(token);
    const getAudiencesRequest = new GetAudiencesRequest();
    const getAudiencesFilter = new GetAudiencesFilter();

    if (search) {
        getAudiencesFilter.search = search;
    }

    getAudiencesRequest.limit = limit;
    getAudiencesRequest.offset = offset;
    getAudiencesRequest.filter = getAudiencesFilter;

    return twitterClient.getAudiences(getAudiencesRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const createTwitterAudienceRequest = async (token, name) => {
    const headers = getConnectRpcHeaders(token);
    const audience = new Audience();
    const createAudienceRequest = new CreateAudienceRequest();

    audience.name = name;
    createAudienceRequest.audience = audience;

    return twitterClient.createAudience(createAudienceRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const sendAudienceToTwitterRequest = async (token, id) => {
    const headers = getConnectRpcHeaders(token);
    const sendAudienceToTwitterRequest = new SendAudienceToTwitterRequest();

    sendAudienceToTwitterRequest.id = id;

    return twitterClient.sendAudienceToTwitter(sendAudienceToTwitterRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const removeTwitterAudienceRequest = async (token, id) => {
    const headers = getConnectRpcHeaders(token);
    const removeAudienceRequest = new RemoveAudienceRequest();

    removeAudienceRequest.id = id;

    return twitterClient.removeAudience(removeAudienceRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const createTwitterAudienceDonorRequest = async (token, name, audienceId) => {
    const headers = getConnectRpcHeaders(token);
    const createDonorRequest = new CreateDonorRequest();

    createDonorRequest.name = name;
    createDonorRequest.audienceId = audienceId;

    return twitterClient.createDonor(createDonorRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportTwitterAudienceRequest = async (token, id, type) => {
    const headers = getConnectRpcHeaders(token);
    const exportRequest = new ExportRequest();

    exportRequest.audienceId = id;
    exportRequest.type = type;

    return twitterClient.export(exportRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getTwitterAudienceByNameRequest = async (token, name) => {
    const headers = getConnectRpcHeaders(token);
    const getAudienceByNameRequest = new GetAudienceByNameRequest();

    getAudienceByNameRequest.name = name;

    return twitterClient.getAudienceByName(getAudienceByNameRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getTwitterAudienceDonorsRequest = async (token, limit, offset, search, id) => {
    const headers = getConnectRpcHeaders(token);
    const getDonorsRequest = new GetDonorsRequest();
    const getDonorsFilter = new GetDonorsFilter();

    getDonorsFilter.search = search;
    getDonorsFilter.audienceId = id;

    getDonorsRequest.limit = limit;
    getDonorsRequest.offset = offset;
    getDonorsRequest.filter = getDonorsFilter;

    return twitterClient.getDonors(getDonorsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const removeTwitterAudienceDonorRequest = async (token, donorId, audienceId) => {
    const headers = getConnectRpcHeaders(token);
    const removeDonorRequest = new RemoveDonorRequest();

    removeDonorRequest.id = donorId;
    removeDonorRequest.audienceId = audienceId;

    return twitterClient.removeDonor(removeDonorRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const renameTwitterAudienceDonorRequest = async (token, id, name) => {
    const headers = getConnectRpcHeaders(token);
    const renameAudienceRequest = new RenameAudienceRequest();

    renameAudienceRequest.id = id;
    renameAudienceRequest.name = name;

    return twitterClient.renameAudience(renameAudienceRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

// health
export const getServiceHealthRequest = async (token, service) => {
    const headers = getConnectRpcHeaders(token);
    const getHealthRequest = new GetHealthRequest();
    getHealthRequest.service = service;
    let healthArray = [];

    const stream = healthClient.getHealth(getHealthRequest, headers);

    for await (const res of stream) {
        healthArray.push(res);
    }

    return healthArray;
};

export const getAllServicesHealthRequest = async (token, dispatch, succeedGetHealth) => {
    const headers = getConnectRpcHeaders(token);
    const getAllHealthRequest = new GetAllHealthRequest();

    let allHealthList = [];
    const stream = healthClient.getAllHealth(getAllHealthRequest, headers);

    let currentService = "AppStore";
    for await (const res of stream) {
        const health = res;
        const {service} = health;
        if (currentService !== service) {
            dispatch(succeedGetHealth(allHealthList[currentService], currentService));
        }
        //  группируем по сервису
        allHealthList[service] ? allHealthList[service].push(health) : allHealthList[service] = [health];
        currentService = service;
    }
};

// itch.io
const getItchFilter = (genreOptions, languages, languagesCount, platforms, price, releasedDateRange) => {
    const gameFilter = new GameFilter();
    const released = new ConnectDateRange();
    const comparatorFrom = new ConnectRpcComparator();
    const comparatorTo = new ConnectRpcComparator();

    if (genreOptions && genreOptions.length) {
        let genresArray = [];

        forEach(genreOptions, genre => {
            genresArray.push(genre.id);
        })

        gameFilter.genres = genresArray;
    }

    if (languages && languages.length) {
        let languagesArray = [];

        forEach(languages, language => {
            languagesArray.push(language.id);
        })

        gameFilter.languages = languagesArray;
    }

    if (languagesCount?.from || languagesCount?.to) {
        comparatorFrom.value = +languagesCount.from;
        comparatorTo.value = +languagesCount.to;
        comparatorFrom.operator = 3;
        comparatorTo.operator = 5;
        const comparatorArray = [];
        languagesCount.from && comparatorArray.push(comparatorFrom);
        languagesCount.to && comparatorArray.push(comparatorTo);
        gameFilter.languageCountCompare = comparatorArray;
    }

    if (platforms?.length) {
        let platformsArray = [];

        forEach(platforms, platform => {
            platformsArray.push(platform.id);
        })

        gameFilter.platforms = platformsArray;
    }

    if (price?.length === 1) {
        gameFilter.price = +price[0].id;
    }

    if (releasedDateRange) {
        const fromTimestamp = new ConnectRpcTimestamp();
        const toTimestamp = new ConnectRpcTimestamp();

        fromTimestamp.seconds = releasedDateRange.from;
        fromTimestamp.nanos = 0;

        toTimestamp.seconds = releasedDateRange.to;
        toTimestamp.nanos = 0;

        released.from = fromTimestamp;
        released.to = toTimestamp;

        gameFilter.updated = released;
    }

    return gameFilter;
}

const itchGamesRequest = (limit, offset, genreOptions, languages, languagesCount, platforms, price, releasedDateRange) => {
    const getGamesRequest = new ItchGetGamesRequest();

    getGamesRequest.limit = limit;
    getGamesRequest.offset = offset;

    getGamesRequest.filter = getItchFilter(genreOptions, languages, languagesCount, platforms, price, releasedDateRange);

    return getGamesRequest;
}

const itchAuthorsRequest = (limit, offset, genreOptions, languages, languagesCount, platforms, price, releasedDateRange) => {
    const getAuthorsRequest = new GetAuthorsRequest();

    getAuthorsRequest.limit = limit;
    getAuthorsRequest.offset = offset;

    getAuthorsRequest.filter = getItchFilter(genreOptions, languages, languagesCount, platforms, price, releasedDateRange);

    return getAuthorsRequest;
}

export const getItchGamesRequest = async (token, limit, offset, genreOptions, languages, languagesCount, platform, price, released) => {
    const headers = getConnectRpcHeaders(token);

    const getItchRequest = itchGamesRequest(limit, offset, genreOptions, languages, languagesCount, platform, price, released);

    return itchClient.getGames(getItchRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getItchAuthorsRequest = async (token, limit, offset, genreOptions, languages, languagesCount, platform, price, released) => {
    const headers = getConnectRpcHeaders(token);

    const getItchRequest = itchAuthorsRequest(limit, offset, genreOptions, languages, languagesCount, platform, price, released);

   return itchClient.getAuthors(getItchRequest, headers).then(res => res).catch(err => {
       handleServiceError(0, err.message);
       return null;
   });
};

export const getItchFilterDataRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getFilterDataRequest = new ItchGetFilterDataRequest();

   return itchClient.getFilterData(getFilterDataRequest, headers).then(res => res).catch(err => {
       handleServiceError(0, err.message);
       return null;
   });
};

export const getItchLastUpdateRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getLastUpdateRequest = new ItchGetLastUpdateRequest();

    return itchClient.getLastUpdate(getLastUpdateRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportItchGamesRequest = async (token, limit, offset, genreOptions, languages, languagesCount, platform) => {
    const headers = getConnectRpcHeaders(token);
    const exportGamesRequest = new ItchExportGamesRequest();

    exportGamesRequest.request = itchGamesRequest(limit, offset, genreOptions, languages, languagesCount, platform);

    return itchClient.exportGames(exportGamesRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportItchAuthorsRequest = async (token, limit, offset, genreOptions, languages, languagesCount, platform) => {
    const headers = getConnectRpcHeaders(token);
    const exportAuthorsRequest = new ExportAuthorsRequest();

    exportAuthorsRequest.request = itchAuthorsRequest(limit, offset, genreOptions, languages, languagesCount, platform);

    return itchClient.exportAuthors(exportAuthorsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportItchTwittersRequest = async (token, limit, offset, genreOptions, languages, languagesCount, platform) => {
    const headers = getConnectRpcHeaders(token);
    const exportAuthorTwittersRequest = new ExportAuthorTwittersRequest();

    exportAuthorTwittersRequest.request = itchAuthorsRequest(limit, offset, genreOptions, languages, languagesCount, platform);

    return itchClient.exportAuthorTwitters(exportAuthorTwittersRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getCountItch = async (token, genreOptions, languages, languagesCount, platforms, price, releasedDateRange) => {
    const headers = getConnectRpcHeaders(token);
    const countRequest = new ItchCountRequest();

    countRequest.filter = getItchFilter(genreOptions, languages, languagesCount, platforms, price, releasedDateRange);

    return itchClient.count(countRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

// eustartups

const getEuStartupsRequest = (limit, offset, search, country) => {
    const euStartupsRequest = new GetCompaniesRequest();
    const companiesFilter = new CompaniesFilter();

    euStartupsRequest.limit = limit;
    euStartupsRequest.offset = offset;

    if (search) {
        companiesFilter.search = search;
    }

    if (country &&  country !== 'All') {
        companiesFilter.country = country;
    }

    euStartupsRequest.filter = companiesFilter;

    return euStartupsRequest;
}

export const getEuStartupsRecordsRequest = async (token, limit, offset, search, country) => {
    const headers = getConnectRpcHeaders(token);

    const euStartupsRequest = getEuStartupsRequest(limit, offset, search, country);

    return euStartupsClient.getCompanies(euStartupsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getEuStartupsFilterDataRequest = (token) => {
    const headers = getConnectRpcHeaders(token);
    const getFilterDataRequest = new EuStartupsGetFilterDataRequest();

    return euStartupsClient.getFilterData(getFilterDataRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getEuStartupsLastUpdateRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getLastUpdateRequest = new EuStartupsGetLastUpdateRequest();

    return euStartupsClient.getLastUpdate(getLastUpdateRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportEuStartupsCompanyRequest = async (token, limit, offset, search, country) => {
    const headers = getConnectRpcHeaders(token);
    const exportCompanyRequest = new EuStartupsExportCompanyRequest();

    exportCompanyRequest.request = getEuStartupsRequest(limit, offset, search, country);

    return euStartupsClient.exportCompany(exportCompanyRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportEuStartupsTwitterRequest = async (token, limit, offset, search, country) => {
    const headers = getConnectRpcHeaders(token);
    const exportTwitterRequest = new ExportTwitterRequest();

    exportTwitterRequest.request = getEuStartupsRequest(limit, offset, search, country);

    return euStartupsClient.exportTwitter(exportTwitterRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportEuStartupsEmailsRequest = async (token, limit, offset, search, country) => {
    const headers = getConnectRpcHeaders(token);
    const ExportEmailsRequest = new EuStartupsExportEmailsRequest();

    ExportEmailsRequest.request = getEuStartupsRequest(limit, offset, search, country);

    return euStartupsClient.exportEmails(ExportEmailsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getCountEuStartupsRequest = async (token, search, country) => {
    const headers = getConnectRpcHeaders(token);
    const countRequest = new EuStartupsCountRequest();
    const companiesFilter = new CompaniesFilter();

    if (search) {
        companiesFilter.search = search;
    }

    if (country && country !== 'All') {
         companiesFilter.country = country;
    }

    countRequest.filter = companiesFilter;

    return euStartupsClient.count(countRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

// gog

const getGogFilter = (price, releasedDateRange, rating, genreOptions) => {
    const gameFilter = new GogGameFilter();
    const released = new ConnectDateRange();

    if (price?.length === 1) {
        gameFilter.price = price[0].id;
    }

    if (releasedDateRange) {
        const fromTimestamp = new ConnectRpcTimestamp();
        const toTimestamp = new ConnectRpcTimestamp();

        fromTimestamp.seconds = releasedDateRange.from;
        fromTimestamp.nanos = 0;

        toTimestamp.seconds = releasedDateRange.to;
        toTimestamp.nanos = 0;

        released.from = fromTimestamp;
        released.to = toTimestamp;

        gameFilter.releaseDate = released;
    }

    if (!isEmpty(rating)) {
        const comparatorFrom = new ConnectRpcComparator();
        const comparatorTo = new ConnectRpcComparator();
        if (rating.from && rating.from === rating.to) {
            comparatorFrom.value = +rating.from;
            comparatorFrom.operator = 1;
            const comparatorArray = [];
            comparatorArray.push(comparatorFrom);
            gameFilter.ratingCompare = comparatorArray;
        }
        else {
            comparatorFrom.value = +rating.from;
            comparatorTo.value = +rating.to;
            comparatorFrom.operator = 3;
            comparatorTo.operator = 5;
            const comparatorArray = [];
            rating.from && comparatorArray.push(comparatorFrom);
            rating.to && comparatorArray.push(comparatorTo);
            gameFilter.ratingCompare = comparatorArray;
        }
    }

    if (genreOptions?.length) {
        let genresArray = [];

        forEach(genreOptions, genre => {
            genresArray.push(genre.id);
        })

        gameFilter.genres = genresArray;
    }

    return gameFilter;
}

const getGogRequest = (limit, offset, price, released, rating, genres, fields) => {
    const getGamesRequest = new GetGamesRequest();

    const gogFilter = getGogFilter(price, released, rating, genres);

    getGamesRequest.limit = limit;
    getGamesRequest.offset = offset;
    getGamesRequest.filter = gogFilter;

    if (fields) {
        const {fieldsList, uniqueFieldsList} = fields;
        if (!includes(fieldsList, 'email')) {
            getGamesRequest.showFields = fieldsList;
        }
        getGamesRequest.uniqueFields = uniqueFieldsList;
    }

    return getGamesRequest;
}

export const getGogGamesRequest = async (token, limit, offset, price, released, rating, genres) => {
    const headers = getConnectRpcHeaders(token);

    const getGamesRequest = getGogRequest(limit, offset, price, released, rating, genres);

    return gogClient.getGames(getGamesRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getLastUpdateGogRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getLastUpdateRequest = new GogGetLastUpdateRequest();

    return gogClient.getLastUpdate(getLastUpdateRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportGogRequest = async (token, price, released, rating, genres, limit, offset, fields) => {
    const headers = getConnectRpcHeaders(token);
    const exportGamesRequest = new ExportGamesRequest();

    exportGamesRequest.request = getGogRequest(limit, offset, price, released, rating, genres, fields);

    return gogClient.exportGames(exportGamesRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getGogFilterDataRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const getFilterDataRequest = new GogGetFilterDataRequest();

    return gogClient.getFilterData(getFilterDataRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getGogExportCount = async (token, price, released, rating, genres, limit, offset, fields) => {
    const headers = getConnectRpcHeaders(token);
    const countRequest = new GogCountRequest();

    countRequest.filter = getGogFilter(price, released, rating, genres);

    if (fields) {
        const {fieldsList, uniqueFieldsList} = fields;
        if (!includes(fieldsList, 'email')) {
            countRequest.showFields = fieldsList;
        }

        countRequest.uniqueFields = uniqueFieldsList;
    }

    return gogClient.count(countRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

//  MeetToMatch

export const getMeetToMatchConferenceRequest = async (token, limit, offset, search, order, sortField) => {
    const headers = getConnectRpcHeaders(token);

    const getEventsRequest = new GetEventsRequest();

    return meetToMatchClient.getEvents(getEventsRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

const getRequestMeetToMatchPeople = (eventId, limit, offset, search, attendeeTags, preferredDealSizes, lookingFor, industry, platform, countries,
                                     companySizes, greenFlag, redFlag, investmentTypes) => {
    const getPeopleRequest = new GetPeopleRequest();
    const peopleFilter = new PeopleFilter();
    const companyFilter = new CompanyFilter();

    getPeopleRequest.limit = limit;
    getPeopleRequest.offset = offset;

    peopleFilter.eventId = eventId;

    if (attendeeTags?.length) {
        peopleFilter.attendeeTags = convertObjectToArrayOfKeys(attendeeTags);
    }

    if (preferredDealSizes?.length) {
        peopleFilter.preferredDealSize = convertObjectToArrayOfKeys(preferredDealSizes);
    }

    if (lookingFor?.length) {
        peopleFilter.lookingFor = convertObjectToArrayOfKeys(lookingFor);
    }

    if (industry?.length) {
        peopleFilter.industry = convertObjectToArrayOfKeys(industry);
    }

    if (platform?.length) {
        peopleFilter.platform = convertObjectToArrayOfKeys(platform);
    }

    if (investmentTypes?.length) {
        peopleFilter.lookingForInvestmentType = convertObjectToArrayOfKeys(investmentTypes);
    }

    if (countries?.length) {
        companyFilter.countries = convertObjectToArrayOfKeys(countries);
    }

    if (companySizes?.length) {
        companyFilter.sizes = convertObjectToArrayOfKeys(companySizes);
    }

    if (greenFlag) {
        companyFilter.greenFlag = greenFlag;
    }

    if (redFlag) {
        companyFilter.greenFlag = redFlag;
    }

    peopleFilter.companyFilter = companyFilter;
    getPeopleRequest.filter = peopleFilter;

    return getPeopleRequest;
}

export const getMeetToMatchPeopleRequest = async (eventId, token, limit, offset, search, attendeeTags, preferredDealSizes, lookingFor, industry, platform, countries,
                                                  companySizes, greenFlag, redFlag, investmentTypes) => {
    const headers = getConnectRpcHeaders(token);
    const getPeopleRequest = getRequestMeetToMatchPeople(eventId, limit, offset, search, attendeeTags, preferredDealSizes, lookingFor, industry, platform, countries,
        companySizes, greenFlag, redFlag, investmentTypes);

    return meetToMatchClient.getPeople(getPeopleRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const getFilterDataMeetToMatchConferenceRequest = async (token) => {
    const headers = getConnectRpcHeaders(token);
    const meetToMatchGetFilterDataRequest = new MeetToMatchGetFilterDataRequest();

    return meetToMatchClient.getFilterData(meetToMatchGetFilterDataRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};

export const exportMeetToMatchPeopleRequest = async (eventId, token, limit, offset, search, attendeeTags, preferredDealSizes, lookingFor, industry, platform, countries,
                                                     companySizes, greenFlag, redFlag, investmentTypes) => {
    const headers = getConnectRpcHeaders(token);
    const exportPeopleRequest = new ExportPeopleRequest();

    exportPeopleRequest.request = getRequestMeetToMatchPeople(eventId, limit, offset, search, attendeeTags, preferredDealSizes, lookingFor, industry, platform, countries,
        companySizes, greenFlag, redFlag, investmentTypes);

    return meetToMatchClient.exportPeople(exportPeopleRequest, headers).then(res => res).catch(err => {
        handleServiceError(0, err.message);
        return null;
    });
};


